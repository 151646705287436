import { postRequest } from "../libs/AxiosService.util";

export async function serviceGetRegionsMaster(params) {
  return await postRequest("zones", params);
}
export async function serviceCreateRegionsMaster(params) {
  return await postRequest("zones/create", params);
}
export async function serviceUpdateRegionsMaster(params) {
  return await postRequest("zones/update", params);
}
export async function serviceGetRegionsMasterDetails(params) {
  return await postRequest("zones/detail", params);
}
export async function serviceIsExistRegionsMaster(params) {
  return await postRequest("zones/check", params);
}
export async function serviceGetState(params) {
  return await postRequest("states", params);
}
export async function serviceCreateState(params) {
  return await postRequest("states/create", params);
}
export async function serviceUpdateState(params) {
  return await postRequest("states/update", params);
}
export async function serviceGetStateDetails(params) {
  return await postRequest("states/detail", params);
}
export async function serviceIsExistState(params) {
  return await postRequest("states/check", params);
}
export async function serviceGetHqs(params) {
  return await postRequest("hqs", params);
}
export async function serviceCreateHqs(params) {
  return await postRequest("hqs/create", params);
}
export async function serviceUpdateHqs(params) {
  return await postRequest("hqs/update", params);
}
export async function serviceGetHqsDetails(params) {
  return await postRequest("hqs/detail", params);
}
export async function serviceIsExistHqs(params) {
  return await postRequest("hqs/check", params);
}
export async function serviceRegionExport(params) {
    return await postRequest('hqs/region/export', params);
}
export async function serviceRegionStateZone(params) {
  return await postRequest('states/state/zones', params);
}


