import {
  formDataRequest,
  getRequest,
  postRequest,
} from "../libs/AxiosService.util";

export async function serviceCreateProductMaster(params) {
  return await formDataRequest("products/create", params);
}
export async function serviceUpdateProductMaster(params) {
  return await postRequest("products/update", params);
}

export async function serviceDeleteProductMaster(params) {
  return await postRequest("products/delete", params);
}
export async function serviceGetProductMasterDetails(params) {
  return await postRequest("products/detail", params);
}
export async function serviceGetProductMaster(params) {
  return await postRequest("products", params);
}
export async function serviceCheckProductMasterCode(params) {
  return await postRequest("products/check", params);
}

export async function serviceExportProductMaster(params) {
  return await postRequest('products/export', params);
}

