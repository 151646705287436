import React from "react";
import styles from "./Style.module.css";
import { ButtonBase } from "@material-ui/core";
import { Add, ArrowBackIos } from "@material-ui/icons";
import history from "../../../../libs/history.utils";
import AssociatedStateList from "./component/AssociatedStateList";
import useZoneDetail from "./ZoneDetail.hook";
import SidePanelComponent from "../../../../components/SidePanel/SidePanel.component";
import StateCreate from "../StateCreate/StateCreate.view";

const ZoneDetail = () => {
  const {data, detailId,  handleSideToggle,
    isSidePanel,
    editData, editId} = useZoneDetail();
  return (
    <div>
      <div className={styles.upperFlex}>
        <ButtonBase onClick={() => history.goBack()}>
          <ArrowBackIos fontSize={"small"} />{" "}
          <span>
            <b>Zone Details</b>
          </span>
        </ButtonBase>
        <div></div>
        <div className={styles.profileHeading}>
          <ButtonBase
            onClick={() => handleSideToggle()}
            className={"createBtn"}
            id={styles.btnHideResponsive}
          >
            ADD STATE
            <Add fontSize={"small"} className={"plusIcon"}></Add>
          </ButtonBase>
        </div>
      </div>
      <div className={styles.newLine} />
      <div className={styles.plainPaper}>
        <div className={styles.newContainer}>
          <div className={styles.mainFlex}>
            <div className={styles.left221}>
              <div>
                <div className={styles.key}>
                  {console.log(data, "data")}
                  <span className={styles.value}>Zone Name:</span>
                  {data?.name}
                </div>
                <div className={styles.key}>
                  <span className={styles.value}>Code:</span>
                  {data?.code}
                </div>
              </div>
            </div>
            <div className={styles.left}>
              <div className={styles.key}>
                <span className={styles.value}>Associated State Count:</span>
                {data?.stateCount}
              </div>

             
            </div>
          </div>
        </div>
      </div>
      {/* <div className={styles.plainPaper}>
        <div className={styles.newContainer}>
          <div className={styles.mainFlex}>
            <div className={styles.left221}>
              <div>
                <div className={styles.key}>
                  <span className={styles.value}>State Name:</span>
                  {data?.name}
                </div>
                <div className={styles.key}>
                  <span className={styles.value}>State Code:</span>
                  {data?.code}
                </div>
              </div>
            </div>
            <div className={styles.left}>
              <div className={styles.key}>
                <span className={styles.value}>Zone Name:</span>
                {data?.cityChapterCount}
              </div>

              <div className={styles.key}>
                <span className={styles.value}>Associated HQ Count:</span>
                {data?.admin?.name}
              </div>
            </div>
          </div>
        </div>
      </div> */}
      <div>
        <AssociatedStateList detailId={detailId} detailData={data}/>
        <SidePanelComponent
                  handleToggle={handleSideToggle}
                  title={`${editId ? "Edit": "Create"} State`}
                  open={isSidePanel}
                  side={"right"}
                >
                  <StateCreate
                    handleClose={handleSideToggle}
                    id={editData}
                    editId={editId}
                    isOpen={isSidePanel}
                    data={data}
                    detailId={detailId}
                  />
                </SidePanelComponent>
      </div>
    </div>
  );
};

export default ZoneDetail;
