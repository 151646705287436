import React, { useCallback, useEffect, useState } from 'react'
import { useParams } from "react-router";
import {
  serviceGetStateDetails
} from "../../../../services/RegionsMaster.service";
import SnackbarUtils from '../../../../libs/SnackbarUtils';


const useStateDetail = () => {
  const { id: detailId } = useParams();
  const [data, setData] = useState([]);
  const [isSidePanel, setSidePanel] = useState(false);
    const [editData, setEditData] = useState(null);
    const [editId, setEditId] = useState(null);
    const [zoneId, setZoneId] = useState(null);

    useEffect(() => {
      serviceGetStateDetails({ id: detailId  }).then((res) => {
        if (!res?.error) {
          setData(res?.data?.details);
        }
      });
    }, [detailId]);

      const handleSideToggle = useCallback(
        (data) => {
          setSidePanel((e) => !e);
          if (data) {
            setEditData(data);
            setEditId(data?.id);
            setZoneId(data?.zone_id);
            console.log(editId, "id");
           
          }
          console.log(data, "data");
    
        },
        [setEditData, setSidePanel, setEditId, setZoneId]
      );
console.log(data?.zone_id, "j");
  return {
    data,
    detailId,
    handleSideToggle,
    isSidePanel,
    editData,
    editId,
    zoneId
}
}

export default useStateDetail;