import { useDispatch, useSelector } from "react-redux";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { serviceGetList } from "../../services/Common.service";
import SnackbarUtils from "../../libs/SnackbarUtils";
import {
  serviceSalesDetailReportDownload,
  serviceGetSalesDetailReport,
} from "../../services/SalesDetailReport.service";
import historyUtils from "../../libs/history.utils";
import LogUtils from "../../libs/LogUtils";
import Constants from "../../config/constants";

const totalShow = 50;
const initialForm = {
  start_date: "",
  end_date: "",
  type: "",
  // region_type: "",
  zone_id: "",
  state_id: "",
  hq_id: "",
};
const useSalesDetailReport = ({ location }) => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [form, setForm] = useState({ ...initialForm });
  const [currentPage, setCurrentPage] = useState(1);
  const [data, setData] = useState([]);
  const [currentData, setCurrentData] = useState([]);
  const [isCalling, setIsCalling] = useState(false);
  const [apiData, setApiData] = useState([]);
  const [type, setType] = useState("");
  const [year, setYear] = useState("");
  const [formData, setFormData] = useState({});
  const [errorData, setErrorData] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [listData, setListData] = useState({
    STATES: [],
    ZONES: [],
    HQS: [],
  });

  useEffect(() => {
    serviceGetList(["STATES", "ZONES", "HQS"]).then((res) => {
      if (!res.error) {
        setListData(res.data);
      }
    });
  }, []);

  const resetData = useCallback(() => {
    setIsCalling(true);
    serviceGetSalesDetailReport({
      index: 1,
      ...form,
    }).then((res) => {
      if (!res.error) {
        const data = res.data;
        setApiData(data);
      }
      setIsCalling(false);
    });
  }, [form, setIsCalling]);

  // const initialApiCall = useCallback(() => {

  // }, [resetData]);
  const initialApiCall = useCallback(() => {
    resetData();
  }, []);

  useEffect(() => {
    resetData();
  }, [form]);

  useEffect(() => {
    setData(apiData);
  }, [apiData]);

  useEffect(() => {
    setApiData([]); 
    resetData();
  }, [form.type, resetData]);

  const handleDownload = useCallback(() => {
    setIsLoading(true);
    serviceSalesDetailReportDownload({
      ...form,
    }).then((res) => {
      if (!res.error) {
        const data = res.data;
        window.open(data, "_blank");
      }
      setIsLoading(false);
    });
  }, [form]);

  useEffect(() => {
    _processData();
  }, [data, currentPage]);

  const _processData = useCallback(() => {
    const from = currentPage * totalShow - totalShow;
    let to = currentPage * totalShow;
    if (from <= data?.length) {
      to = to <= data?.length ? to : data?.length;
      setCurrentData(data.slice(from, to));
    }
  }, [setCurrentData, currentPage, data, totalShow]);

  const handlePageChange = useCallback(
    (type) => {
      if (Math.ceil(data?.length / totalShow) >= type + 1) {
        setCurrentPage(type + 1);
        _processData();
      }
    },
    [_processData, setCurrentPage, data]
  );

  const queryFilter = useCallback((key, value) => {
    console.log("_queryFilter", key, value);
  }, []);

  const handleFilterDataChange = useCallback(
    (value) => {
      if (value && Array.isArray(value) && value.length > 0) {
        let tData = [...apiData];
        let filteredData = [];
        for (const filterObj of value) {
          const { name, value } = filterObj;

          if (name === "state") {
            const stateObj = listData?.STATES?.find((state) => state.id === value);          
            const stateName = stateObj ? stateObj.name : "";
          
            if (stateName) {
              tData = tData.filter((obj) => {
                return obj.state?.name === stateName; 
              });
            }
          }
          
           else if (name === "states") {
            const stateObj = listData?.STATES?.find((state) => state.id === value);          
            const stateName = stateObj ? stateObj.name : "";

          
            if (stateName) {
              tData = tData.filter((obj) => {
                return obj.states?.some((state) => state.name === stateName); 
              });
            }
          }  else if (name === "hq") {
            const hqObj = listData?.HQS?.find((hq) => hq.id === value);
            const hqName = hqObj ? hqObj.name : "";
          
            if (hqName) {
              tData = tData.filter((obj) => {
               
                return obj.hq?.name === hqName; 
              });
            }
          }   
           else if (name === "hqs") {
            const hqObj = listData?.HQS?.find((hq) => hq.id === value);
            const hqName = hqObj ? hqObj.name : "";
  
            if (hqName) {
              tData = tData.filter((obj) =>
                obj.hqs?.some((hq) => hq.name === hqName)
              );
            }
          } else {
            tData = tData.filter((obj) => {
              if (value === "YES") {
                return obj.increment_percentage != obj.final_percentage;
              } else {
                return obj.increment_percentage == obj.final_percentage;
              }
            });
          }
        }
        setData(tData);
      } else {
        setData(apiData);
      }
    },
    [queryFilter, apiData, data, setData]
  );

  const handleSearchValueChange = useCallback(
    (value) => {
      console.log("_handleSearchValueChange", value);
      queryFilter("SEARCH_TEXT", value);
      if (value) {
        const tempData = apiData?.filter((val) => {
          if (
            val?.name?.match(new RegExp(value, "ig")) ||
            val?.emp_code?.match(new RegExp(value, "ig")) ||
            val?.hqs?.some((hq) => hq.name.match(new RegExp(value, "ig"))) ||
            val?.hqs?.some((hq) => hq.code.match(new RegExp(value, "ig"))) ||
            val?.hq?.name?.match(new RegExp(value, "ig")) ||
            val?.hq?.code?.match(new RegExp(value, "ig")) ||
            val?.zone?.name?.match(new RegExp(value, "ig"))

          ) {
            return val;
          }
        });
        setData(tempData);
        setCurrentPage(1);
      } else {
        setData(apiData);
      }
    },
    [queryFilter, _processData, data, setData, apiData]
  );

  const handleSortOrderChange = useCallback(
    (row, order) => {
      console.log(`handleSortOrderChange key:${row} order: ${order}`);
    },
    [resetData]
  );

  const handleRowSize = (page) => {
    console.log(page);
  };

  const configFilter = useMemo(() => {
    if (form?.type === "REGION") {
      return [
        {
          label: "State",
          name: "state", 
          type: "selectObject",
          custom: { extract: { id: "id", title: "label" } },
          fields: listData?.STATES,
        },
        {
          label: "HQ",
          name: "hq", 
          type: "selectObject",
          custom: { extract: { id: "id", title: "label" } },
          fields: listData?.HQS,
        },
      ];
    } else {
      return [
        {
          label: "States",
          name: "states",
          type: "selectObject",
          custom: { extract: { id: "id", title: "label" } },
          fields: listData?.STATES,
        },
        {
          label: "HQS",
          name: "hqs",
          type: "selectObject",
          custom: { extract: { id: "id", title: "label" } },
          fields: listData?.HQS,
        },
      ];
    }
  }, [form?.type, listData]);
  

  const removeError = useCallback(
    (title) => {
      const temp = JSON.parse(JSON.stringify(errorData));
      temp[title] = false;
      setErrorData(temp);
    },
    [setErrorData, errorData]
  );

  const changeTextData = useCallback(
    (text, fieldName) => {
      let shouldRemoveError = true;
      const t = { ...form };
      const errors = { ...errorData };
      t[fieldName] = text;
      if (fieldName === "type") {
        t.region_type = "";
        t.zone_id = "";
        t.state_id = "";
        t.hq_id = "";
      }
      if (fieldName === "region_type") {
        t.zone_id = "";
        t.state_id = "";
        t.hq_id = "";
      }
      if (t?.start_date && t?.end_date) {
              const startDate = new Date(t?.start_date);
              const endDate = new Date(t?.end_date);
              startDate.setHours(0, 0, 0, 0);
              endDate.setHours(0, 0, 0, 0);
              if (startDate.getTime() > endDate.getTime()) {
                SnackbarUtils.error("Start date should not be greater than end date");
                t["end_date"] = "";
              }
      }
      setForm(t);

      shouldRemoveError && removeError(fieldName);
    },
    [removeError, form, setForm]
  );

  return {
    handlePageChange,
    handleFilterDataChange,
    handleSearchValueChange,
    handleRowSize,
    handleSortOrderChange,
    isCalling,
    currentData,
    data: data,
    currentPage,
    year,
    listData,
    type,
    setType,
    // initialApiCall,
    configFilter,
    handleDownload,
    setYear,
    isSubmitting,
    form,
    changeTextData,
    listData,
    isLoading
  };
};

export default useSalesDetailReport;
