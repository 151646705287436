import React, { useCallback, useMemo } from "react";
import {
  IconButton,
  MenuItem,
  ButtonBase,
  Tooltip,
  makeStyles,
  Checkbox,
  CircularProgress,
  InputAdornment,
} from "@material-ui/core";
import classNames from "classnames";
import PageBox from "../../components/PageBox/PageBox.component";
import styles from "./Style.module.css";
import Constants from "../../config/constants";
import StatusPill from "../../components/Status/StatusPill.component";
import CustomSelectField from "../../components/FormFields/SelectField/SelectField.component";
import FilterComponent from "../../components/Filter/Filter.component";
import useIncrementPlanner from "./SalesDetailReport.hook";
import TablePagination from "@material-ui/core/TablePagination";
import { removeUnderScore } from "../../helper/helper";
import CustomDatePicker from "../../components/FormFields/DatePicker/CustomDatePicker";
import CustomAutoComplete from "../../components/FormFields/AutoCompleteText/CustomAutoComplete";
import SearchIcon from "@material-ui/icons/Search";

const useStyles = makeStyles((theme) => ({
  customTooltip: {
    backgroundColor: "white",
    color: "black",
    fontSize: ".75rem",
    fontWeight: "normal",
    fontFamily: "Montserrat",
    borderRadius: "10px",
  },
}));

const TableCell = ({
  row,
  key,
  fixed,
  readOnly,
  render,
  handleInputChange,
  name,
  value,
  isError,
  group,
  title,
  totalAvg,
  rating,
  index,
  ...props
}) => {
  return (
    <td
      key={key}
      style={{
        position: fixed ? "sticky" : "static",
        left: fixed ? 0 : undefined,
        // border:  "2px solid #EBEDF4",
        padding: "0",
        zIndex: fixed ? 10 : 9,
        background: readOnly ? "#EDF2F5 " : "#ffffff",
      }}
    >
      {render(row[key], row, index)}
    </td>
  );
};

const TableHead = ({ columns }) => {
  const classes = useStyles();

  return (
    <thead>
      <tr>
        {columns?.map(({ key, parameters, label, fixed, text, is_static }) => (
          <>
            <th
              rowSpan={is_static ? 2 : 1}
              colSpan={parameters ? parameters.length : 1}
              key={key}
              style={{
                position: "sticky",
                left: fixed ? 0 : undefined,
                top: 0,
                zIndex: fixed ? 100 : 9,
              }}
              className={styles.thead}
            >
              {key === "name" ? (
                <div className={styles.tipWrap2}>{label}</div>
              ) : (
                <div className={styles.tipWrap}>{label}</div>
              )}
            </th>
          </>
        ))}
      </tr>
    </thead>
  );
};

const SalesDetailReport = ({ location }) => {
  const {
    handlePageChange,
    handleFilterDataChange,
    handleSearchValueChange,
    handleRowSize,
    handleSortOrderChange,
    isCalling,
    currentData,
    data,
    currentPage,
    configFilter,
    handleValueChange,
    changeTextData,
    form,
    listData,
    handleDownload,
    isLoading,
  } = useIncrementPlanner({ location });

  const renderStatus = useCallback((status) => {
    return <StatusPill status={status} />;
  }, []);

  const RenderList = ({ items, field }) => {
    const [expanded, setExpanded] = React.useState(false);
    const safeItems = Array.isArray(items) ? items : [];
    const visibleItems = expanded ? safeItems : safeItems?.slice(0, 5);
    return (
      <div className={styles.noWrapCol}>
        {visibleItems?.map((item) => item[field]).join(", ")}
        {safeItems?.length > 5 && (
          <span
            className={styles.viewButton}
            onClick={() => setExpanded(!expanded)}
          >
            {expanded ? "View Less" : "View More"}
          </span>
        )}
      </div>
    );
  };

  const tableStructure = useMemo(() => {
    if (form?.type === "REGION") {
      return [
        {
          key: "name",
          label: <div className={styles.checkBoxEMp}>ZONE NAME</div>,
          sortable: false,
          fixed: true,
          render: (temp, all) => (
            <div className={styles.noWrapName}>{all?.zone?.name}</div>
          ),
        },

        {
          key: "designation",
          label: "ZONE CODE",
          sortable: false,
          render: (temp, all) => (
            <div className={styles.noWrap}>{all?.zone?.code}</div>
          ),
        },
        {
          key: "cadre",
          label: "STATE NAME",
          sortable: true,
          render: (value, all) => (
            <div className={styles.noWrap}>{all?.state?.name}</div>
          ),
        },
        {
          key: "location",
          label: "STATE CODE",
          sortable: false,
          render: (temp, all) => (
            <div className={styles.noWrap}>{all?.state?.code}</div>
          ),
        },
        {
          key: "department",
          label: "ASSOCIATED HQ",
          sortable: true,
          render: (value, all) => (
            <div className={styles.noWrap}>{all?.hq?.name}</div>
          ),
        },

        {
          key: "doj",
          label: "HQ CODE",
          sortable: true,
          render: (value, all) => (
            <div className={styles.noWrap}>{all?.hq?.code}</div>
          ),
        },
        {
          key: "final",
          label: "TARGET RECEIVED",
          sortable: false,
          render: (temp, all) => (
            <div className={styles.noWrap}>{all?.target}</div>
          ),
        },

        {
          key: "cat",
          label: "PRIMARY TARGET ACHIEVED",
          sortable: false,
          render: (temp, all) => (
            <div className={styles.noWrap}>{all?.achieved}</div>
          ),
        },
        {
          key: "pli",
          label: "SECONDARY TARGET ACHIEVED",
          sortable: false,
          render: (temp, all) => (
            <div className={styles.noWrap}>{all?.sec_achieved}</div>
          ),
        },
        {
          key: "incremental",
          label: "P/S RATIO",
          sortable: false,
          render: (temp, all) => (
            <div className={styles.noWrap}>{all?.p_s_ratio}</div>
          ),
        },
        {
          key: "increment",
          label: "ACHIEVEMENT%",
          sortable: false,
          render: (temp, all) => (
            <div className={styles.noWrap}>{all?.achieved_pct}</div>
          ),
        },
      ];
    } else {
      return [
        {
          key: "name",
          label: <div className={styles.checkBoxEMp}>EMPLOYEE INFO</div>,
          sortable: false,
          fixed: true,
          render: (temp, all) => (
            <div className={styles.noWrapName}>
              {all?.name}
              <br />
              {all?.emp_code}
            </div>
          ),
        },
        {
          key: "designation",
          label: "REVIEWER INFO",
          sortable: false,
          render: (temp, all) => (
            <div className={styles.noWrapName}>
              {all?.pms_reviewer?.name}
              <br />
              {all?.pms_reviewer?.code}
            </div>
          ),
        },
        {
          key: "cadre",
          label: "EMPLOYEE ZONE",
          sortable: true,
          render: (value, all) => (
            <div className={styles.noWrap}>
              {Array.isArray(all?.zones)
                ? all.zones.map((zone) => zone.name).join(", ")
                : "N/A"}
            </div>
          ),
        },
        {
          key: "location",
          label: "ZONE CODE",
          sortable: false,
          render: (temp, all) => (
            <div className={styles.noWrap}>
              {Array.isArray(all?.zones)
                ? all.zones.map((zone) => zone.code).join(", ")
                : "N/A"}
            </div>
          ),
        },
        {
          key: "department",
          label: "EMPLOYEE STATE",
          sortable: false,
          render: (value, all) => (
            <RenderList items={all?.states} field="name" />
          ),
        },

        {
          key: "doj",
          label: "STATE CODE",
          sortable: true,
          render: (value, all) => (
            <RenderList items={all?.states} field="code" />
          ),
        },
        {
          key: "length",
          label: "EMPLOYEE ASSOCIATED HQ",
          sortable: false,
          render: (temp, all) => <RenderList items={all?.hqs} field="name" />,
        },
        {
          key: "quali",
          label: "HQ CODE",
          sortable: false,
          render: (temp, all) => <RenderList items={all?.hqs} field="code" />,
        },
        {
          key: "final",
          label: "TARGET RECEIVED",
          sortable: false,
          render: (temp, all) => (
            <div className={styles.noWrap}>{all?.target}</div>
          ),
        },

        {
          key: "cat",
          label: "PRIMARY TARGET ACHIEVED",
          sortable: false,
          render: (temp, all) => (
            <div className={styles.noWrap}>{all?.achieved}</div>
          ),
        },
        {
          key: "salary",
          label: "YPM",
          sortable: false,
          render: (temp, all) => (
            <div className={styles.noWrap}>{all?.ypm}</div>
          ),
        },
        {
          key: "pli",
          label: "SECONDARY TARGET ACHIEVED",
          sortable: false,
          render: (temp, all) => (
            <div className={styles.noWrap}>{all?.sec_achieved}</div>
          ),
        },
        {
          key: "incremental",
          label: "P/S RATIO",
          sortable: false,
          render: (temp, all) => (
            <div className={styles.noWrap}>{all?.p_s_ratio}</div>
          ),
        },

        {
          key: "increment",
          label: "ACHIEVEMENT%",
          sortable: false,
          render: (temp, all) => (
            <div className={styles.noWrap}>{all?.achieved_pct}</div>
          ),
        },
        // {
        //   key: "effective",
        //   label: "DOCTOR COUNT",
        //   sortable: false,
        //   render: (temp, all) => (
        //     <div className={styles.noWrap}>{all?.
        //       doctors_count
        //       }</div>
        //   ),
        // },
        {
          key: "new_salary",
          label: "DOCTOR CALL AVERAGE",
          sortable: false,
          render: (temp, all) => (
            <div className={styles.noWrap}>{all?.dr_call_avg}</div>
          ),
        },
        {
          key: "reviewer",
          label: "MCL COVERAGE",
          sortable: false,
          render: (temp, all) => (
            <div className={styles.noWrap}>{all?.mcl_coverage}</div>
          ),
        },
        {
          key: "yield",
          label: "Outflow/1000",
          sortable: false,
          render: (temp, all) => (
            <div className={styles.noWrap}>
              {all?.yield}
            </div>
          ),
        },
        {
          key: "salary",
          label: "SALARY",
          sortable: false,
          render: (temp, all) => (
            <div className={styles.noWrap}>{all?.salary}</div>
          ),
        },
      ];
    }
  }, [renderStatus, isCalling, handleValueChange, form, RenderList]);
  // const tableStructure = useMemo(() => {

  // }, [renderStatus, renderFirstCell, isCalling, handleValueChange, form]);

  const tableData = useMemo(() => {
    const datatableFunctions = {
      onSortOrderChange: handleSortOrderChange,
      onPageChange: handlePageChange,
      onRowSizeChange: handleRowSize,
    };

    const datatable = {
      ...Constants.DATATABLE_PROPERTIES,
      columns: tableStructure,
      data: currentData,
      count: data?.length,
      page: currentPage - 1,
      rowsPerPage: 50,
      allRowSelected: false,
      showSelection: false,
    };

    return { datatableFunctions, datatable };
  }, [
    tableStructure,
    handleSortOrderChange,
    handlePageChange,
    handleRowSize,
    data,
    currentPage,
  ]);

  return (
    <div>
      <PageBox>
        <div className={styles.headerContainer}>
          <div>
            <span className={styles.title}>Sales Detail Report</span>
            <div className={styles.newLine} />
          </div>
          <div className={styles.rightFlex}>
            <ButtonBase
              className={styles.download}
              onClick={handleDownload}
              disabled={isLoading}
            >
              {isLoading ? <CircularProgress size={20} /> : "DOWNLOAD"}
            </ButtonBase>
          </div>
        </div>

        <div className={"formFlex"}>
          <div className={"formGroup"}>
            <CustomDatePicker
              clearable
              label={"Start Date"}
              onChange={(date) => {
                changeTextData(date, "start_date");
              }}
              value={form?.start_date}
              // isError={errorData?.pro_start_date}
            />
          </div>
          <div className={"formGroup"}>
            <CustomDatePicker
              clearable
              label={"End Date"}
              onChange={(date) => {
                changeTextData(date, "end_date");
              }}
              value={form?.end_date}
            />
          </div>
          <div className={"formGroup"}>
            <CustomSelectField
              label={"Report Type"}
              value={form?.type}
              handleChange={(value) => {
                changeTextData(value, "type");
              }}
            >
              <MenuItem value={"EMPLOYEE"}>Employee Wise</MenuItem>
              <MenuItem value={"REGION"}>Region Wise</MenuItem>
            </CustomSelectField>
          </div>
          <div className={"formGroup"}>
            {form?.type === "REGION" && (
              <CustomSelectField
                label={"Region Type"}
                value={form?.region_type}
                handleChange={(value) => {
                  // setType(value);
                  changeTextData(value, "region_type");
                }}
              >
                <MenuItem value={"ALL"}>All</MenuItem>
                <MenuItem value={"ZONE"}>Zone</MenuItem>
                <MenuItem value={"STATE"}>State</MenuItem>
                <MenuItem value={"HQ"}>HQ</MenuItem>
              </CustomSelectField>
            )}
          </div>
          {form?.type === "REGION" && form?.region_type === "STATE" && (
            <div className={"formGroup"}>
              <CustomAutoComplete
                autoCompleteProps={{
                  freeSolo: false,
                  getOptionLabel: (option) => option?.name || "",
                }}
                dataset={listData.STATES}
                datasetKey={"name"}
                onTextChange={(selectedState) => {
                  changeTextData(selectedState?.id || "", "state_id");
                }}
                value={listData.STATES.find((state) => state.id === form?.state_id) || null}
                placeholder="Select State"
                variant={"outlined"}
                InputLabelProps={{ shrink: true }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton>
                        <SearchIcon fontSize="small" />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </div>
          )}
          {form?.type === "REGION" && form?.region_type === "ZONE" && (
            <div className={"formGroup"}>
              <CustomAutoComplete
                autoCompleteProps={{
                  freeSolo: false,
                  getOptionLabel: (option) => option?.name || "",
                }}
                dataset={listData.ZONES}
                datasetKey={"name"}
                onTextChange={(selectedZone) => {
                  changeTextData(selectedZone?.id || "", "zone_id");
                }}
                value={listData.ZONES.find((zone) => zone.id === form?.zone_id) || null}
                placeholder="Select Zone"
                variant={"outlined"}
                InputLabelProps={{ shrink: true }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton>
                        <SearchIcon fontSize="small" />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </div>
          )}
          {form?.type === "REGION" && form?.region_type === "HQ" && (
            <div className={"formGroup"}>
              {/* <CustomSelectField
                label={"Select Region"}
                value={form?.hq_id}
                handleChange={(value) => {
                  changeTextData(value, "hq_id");
                }}
              >
                {listData?.HQS?.map((dT) => {
                  return (
                    <MenuItem value={dT?.id} key={dT?.id}>
                      {dT?.name}
                    </MenuItem>
                  );
                })}
              </CustomSelectField> */}
              <CustomAutoComplete
                autoCompleteProps={{
                  freeSolo: false,
                  getOptionLabel: (option) => option?.name || "",
                }}
                dataset={listData.HQS}
                datasetKey={"name"}
                onTextChange={(selectedHq) => {
                  changeTextData(selectedHq?.id || "", "hq_id");
                }}
                value={listData.HQS.find((hq) => hq.id === form?.hq_id) || null}
                placeholder="Select HQ"
                variant={"outlined"}
                InputLabelProps={{ shrink: true }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton>
                        <SearchIcon fontSize="small" />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </div>
          )}
        </div>
        <div>
          <div>
            <FilterComponent
              is_progress={isCalling}
              filters={configFilter}
              handleSearchValueChange={handleSearchValueChange}
              handleFilterDataChange={handleFilterDataChange}
            />
            <br />
            <div style={{ width: "100%", marginBottom: "50px" }}>
              <div className={styles.tableWrapper}>
                <div className={styles.container}>
                  <table
                    style={{
                      borderCollapse: "collapse",
                      cellSpacing: "0",
                      borderSpacing: "0",
                      cellpadding: "0",
                      height: "100px",
                    }}
                  >
                    <TableHead columns={tableStructure} />
                    <tbody>
                      {currentData.map((row, rowIndex) => (
                        <tr key={row.id}>
                          {tableStructure.map(
                            (
                              { key, fixed, readOnly, render, ...props },
                              index
                            ) => (
                              <TableCell
                                row={row}
                                key={key}
                                fixed={fixed}
                                render={render}
                                index={index}
                                {...props}
                              />
                            )
                          )}
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
              <TablePagination
                rowsPerPageOptions={[]}
                component="div"
                count={data?.length}
                rowsPerPage={50}
                page={currentPage - 1}
                onChangePage={(event, newPage) => {
                  handlePageChange(newPage);
                }}
              />
            </div>
          </div>
        </div>
      </PageBox>
    </div>
  );
};

export default SalesDetailReport;
