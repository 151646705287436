import {
  formDataRequest,
  getRequest,
  postRequest,
} from "../libs/AxiosService.util";

export async function serviceCreateBrandMaster(params) {
  return await formDataRequest("brands/create", params);
}
export async function serviceUpdateBrandMaster(params) {
  return await postRequest("brands/update", params);
}

export async function serviceDeleteBrandMaster(params) {
  return await postRequest("brands/delete", params);
}
export async function serviceGetBrandMasterDetails(params) {
  return await postRequest("brands/detail", params);
}
export async function serviceGetBrandMaster(params) {
  return await postRequest("brands", params);
}
export async function serviceCheckBrandMasterCode(params) {
  return await postRequest("brands/check", params);
}

