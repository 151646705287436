/**
 * Created by charnjeetelectrovese@gmail.com on 12/20/2019.
 */
import { createBrowserHistory } from "history";
import LogUtils from "./LogUtils";

const history = createBrowserHistory();

const goBackFunc = history.goBack;

history.goBack = () =>  {
    if (history.length > 1) {
        goBackFunc();
    } else {
        history.replace('/my/profile');
    }
}

export default history;
