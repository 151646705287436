import { postRequest, formDataRequest } from "../libs/AxiosService.util";

export async function serviceCreateAchievementTarget(params) {
  return await formDataRequest("cr/budget/edit/versions/create", params);
}

export async function serviceUpdateAchievementTarget(params) {
  return await postRequest("cr/update", params);
}

export async function serviceDeleteAchievementTarget(params) {
  return await postRequest("cr/delete", params);
}

export async function serviceGetAchievementTarget(params) {
  return await postRequest("doctors/calls", params);
}
export async function serviceGetAchievementTargetStatus(params) {
  return await postRequest("sales/target/status", params);
}
export async function serviceAchievementTargetDetail(params) {
  return await postRequest("cr/budget/detail", params);
}
export async function serviceAchievementTargetSend(params) {
  return await postRequest("sales/target/achieved/send", params);
}
export async function serviceAchievementTargetImportVerify(params) {
  return await formDataRequest("doctors/calls/import/verify", params);
}
export async function serviceAchievementTargetImportFile(params) {
  return await formDataRequest("doctors/calls/import", params);
}
