import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { isAlphaNumChars, isSpace } from "../../../libs/RegexUtils";

import useDebounce from "../../../hooks/DebounceHook";
import {
  serviceCheckProductMasterCode,
  serviceCreateProductMaster,
  serviceGetProductMasterDetails,
  serviceUpdateProductMaster,
} from "../../../services/ProductMaster.service";
import SnackbarUtils from "../../../libs/SnackbarUtils";
import historyUtils from "../../../libs/history.utils";
import RouteName from "../../../routes/Route.name";
import { useParams } from "react-router";
import Constants from "../../../config/constants";
import { serviceGetList } from "../../../services/Common.service";

const initialForm = {
  name: "",
  code: "",
  product_category_id: "",
  brand_id: "",
  division: "",
  is_active: true,
};

const useProductMasterCreate = ({}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [errorData, setErrorData] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [form, setForm] = useState({ ...initialForm });
  const [isEdit, setIsEdit] = useState(false);
  const [listData, setListData] = useState({
    BRANDS: [],
    PRODUCT_CATEGORIES: [],
  });
  const codeDebouncer = useDebounce(form?.code, 500);
  const { id } = useParams();

  useEffect(() => {
    serviceGetList(["BRANDS", "PRODUCT_CATEGORIES"]).then((res) => {
      if (!res.error) {
        setListData(res.data);
      }
    });
  }, []);
  useEffect(() => {
    if (id) {
      setIsLoading(true);
      serviceGetProductMasterDetails({ id }).then((res) => {
        if (!res.error) {
          const data = res?.data?.details;
          setForm({
            name: data?.name,
            code: data?.code,
            product_category_id: data?.product_category,
            brand_id: data?.brand,
            division: data?.division,
            is_active: Constants.GENERAL_STATUS.ACTIVE === data?.status,
          });
        } else {
          SnackbarUtils.error(res?.message);
        }
        setIsLoading(false);
      });
    }
  }, [id]);

  const checkCodeValidation = useCallback(() => {
    serviceCheckProductMasterCode({
      code: form?.code,
      id: id ? id : null,
    }).then((res) => {
      if (!res.error) {
        const errors = JSON.parse(JSON.stringify(errorData));
        if (res.data.is_exists) {
          errors["code"] = "ProductMaster Code Exists";
          setErrorData(errors);
        } else {
          delete errors.code;
          setErrorData(errors);
        }
      }
    });
  }, [errorData, setErrorData, form?.code, id]);

  useEffect(() => {
    if (codeDebouncer) {
      checkCodeValidation();
    }
  }, [codeDebouncer]);

  const checkFormValidation = useCallback(() => {
    const errors = { ...errorData };
    let required = [
      "name",
      "code",
      "product_category_id",
      "brand_id",
      "division",
    ];
    required.forEach((val) => {
      if (
        !form?.[val] ||
        (Array.isArray(form?.[val]) && form?.[val].length === 0)
      ) {
        errors[val] = true;
      } else if (["code"].indexOf(val) < 0) {
        delete errors[val];
      }
    });
    Object.keys(errors).forEach((key) => {
      if (!errors[key]) {
        delete errors[key];
      }
    });
    return errors;
  }, [form, errorData]);

  const submitToServer = useCallback(() => {
    if (!isSubmitting) {
      setIsSubmitting(true);
      let req = serviceCreateProductMaster;
      if (id) {
        req = serviceUpdateProductMaster;
      }
      const updatedFd = {
        ...form,
        product_category_id: form?.product_category_id?.id,
        brand_id: form?.brand_id?.id,
      };
      req({ ...updatedFd, ...(id && { id }) }).then((res) => {
        if (!res.error) {
          historyUtils.push(RouteName.PRODUCT_MASTER);
        } else {
          SnackbarUtils.error(res.error);
        }
        setIsSubmitting(false);
      });
    }
  }, [form, isSubmitting, setIsSubmitting, id]);

  const handleSubmit = useCallback(async () => {
    const errors = checkFormValidation();
    if (Object.keys(errors).length > 0) {
      setErrorData(errors);
      return true;
    }
    submitToServer();
  }, [checkFormValidation, setErrorData, form]);

  const removeError = useCallback(
    (title) => {
      const temp = JSON.parse(JSON.stringify(errorData));
      temp[title] = false;
      setErrorData(temp);
    },
    [setErrorData, errorData]
  );

  const changeTextData = useCallback(
    (text, fieldName) => {
      let shouldRemoveError = true;
      const t = { ...form };
      if (fieldName === "name") {
        if (!text || (isAlphaNumChars(text) && text.toString().length <= 30)) {
          t[fieldName] = text;
        }
      } else if (fieldName === "code") {
        if (!text || (!isSpace(text) && isAlphaNumChars(text))) {
          t[fieldName] = text.toUpperCase();
        }
        shouldRemoveError = false;
      } else {
        t[fieldName] = text;
      }
      setForm(t);
      shouldRemoveError && removeError(fieldName);
    },
    [removeError, form, setForm]
  );

  const onBlurHandler = useCallback(
    (type) => {
      if (form?.[type]) {
        changeTextData(form?.[type].trim(), type);
      }
    },
    [changeTextData, checkCodeValidation]
  );

  const handleDelete = useCallback(() => {}, []);

  const handleReset = useCallback(() => {
    setForm({ ...initialForm });
  }, [form]);

  return {
    form,
    changeTextData,
    onBlurHandler,
    removeError,
    handleSubmit,
    isLoading,
    isSubmitting,
    errorData,
    isEdit,
    handleDelete,
    handleReset,
    id,
    listData,
  };
};

export default useProductMasterCreate;
