import { useDispatch, useSelector } from "react-redux";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import {
  actionFetchState,
  actionSetPageState,
} from "../../../../../actions/AssociatedState.action";
import historyUtils from "../../../../../libs/history.utils";
import RouteName from "../../../../../routes/Route.name";
import {serviceGetList} from "../../../../../services/Common.service";
import history from "../../../../../libs/history.utils";


const useAssociatedStateList = ({ detailId: id, detailData  }) => {
  const [isSidePanel, setSidePanel] = useState(false);
  const [isCalling, setIsCalling] = useState(false);
  const [editData, setEditData] = useState(null);
  const dispatch = useDispatch();
  const isMountRef = useRef(false);
  const [employees, setEmployees] = useState([]);
  const [isCsvDialog, setIsCsvDialog] = useState(false);
  const [editId, setEditId] = useState(null);
  const {
    sorting_data: sortingData,
    is_fetching: isFetching,
    query,
    query_data: queryData,
  } = useSelector((state) => state.state);

  useEffect(() => {
   
    dispatch(
      actionFetchState(1, sortingData, {
        query: isMountRef.current ? query : null,
        query_data: isMountRef.current ? queryData : null,
        zone_id: id,
      } ),
    );
  
    isMountRef.current = true;
  }, [id]);

    const initData = useCallback(() => {
      dispatch(
        actionFetchState(1, sortingData, {
          query: isMountRef.current ? query : null,
          query_data: isMountRef.current ? queryData : null,
        })
      );
    }, []);

  // const handleCellClick = (rowIndex, columnIndex, row, column) => {
  //     console.log(`handleCellClick rowIndex: ${rowIndex} columnIndex: ${columnIndex}`);
  // }
  // const handlePreviousPageClick = () => {
  //     console.log('handlePreviousPageClick', 'PREV');
  // }
  //
  // const handleNextPageClick = () => {
  //     console.log('handleNextPageClick', 'NEXT');
  // }

  const handlePageChange = useCallback((type) => {
    console.log("_handlePageChange", type);
    dispatch(actionSetPageState(type));
  }, []);

  const handleDataSave = useCallback(
    (data, type) => {
      setSidePanel((e) => !e);
      setEditData(null);
    },
    [setSidePanel, setEditData]
  );
  const changeEmployeeRoute = useCallback((data) => {
    historyUtils.push(`/employees/details/${data?.code}`);
}, []);
  const queryFilter = useCallback(
    (key, value) => {
      console.log("_queryFilter", key, value);
      // dispatch(actionSetPageStateRequests(1));
      dispatch(
        actionFetchState(1, sortingData, {
          query: key == "SEARCH_TEXT" ? value : query,
          query_data: key == "FILTER_DATA" ? value : queryData,
          zone_id: id,
        })
      );
      // dispatch(actionFetchState(1, sortingData))
    },
    [sortingData, query, queryData, id]
  );

  const handleFilterDataChange = useCallback(
    (value) => {
      console.log("_handleFilterDataChange", value);
      queryFilter("FILTER_DATA", value);
    },
    [queryFilter]
  );

  const handleSearchValueChange = useCallback(
    (value) => {
      console.log("_handleSearchValueChange", value);
      queryFilter("SEARCH_TEXT", value);
      
    },
    [queryFilter]
  );

  const handleSortOrderChange = useCallback(
    (row, order) => {
      console.log(`handleSortOrderChange key:${row} order: ${order}`);
      // dispatch(actionSetPageState(1));
      dispatch(
        actionFetchState(
          1,
          { row, order },
          {
            query: query,
            query_data: queryData,
          }
        )
      );
    },
    [query, queryData]
  );

  const handleRowSize = (page) => {
    console.log(page);
  };

  const handleDelete = useCallback(
    (id) => {
      setSidePanel(false);
      setEditData(null);
    },
    [setEditData, setSidePanel]
  );

  const handleEdit = useCallback(
    (data) => {
      setEditData(data);
      setSidePanel((e) => !e);
    },
    [setEditData, setSidePanel]
  );

  const handleSideToggle = useCallback(
    (data) => {
      setSidePanel((e) => !e);
      if (data) {
        setEditData(data);
        setEditId(data?.id);
        console.log(editId, "id");
       
      }
      console.log(data, "data");
      // history.push({ state: { doctor_upload }})

      // console.log(doctor_upload, "ww")
    },
    [setEditData, setSidePanel, setEditId]
  );
console.log(editData, "dd");
  const handleViewDetails = useCallback((data) => {
    historyUtils.push(RouteName.STATE_DETAILS + data.id); //+data.id
  }, []);

  const handleCreate = useCallback(() => {
    historyUtils.push(RouteName.LOCATIONS_CREATE);
  }, []);

  const configFilter = useMemo(() => {
    return [
    
      // {
      //   label: "Status",
      //   name: "status",
      //   type: "selectObject",
      //   custom: { extract: { id: "id", title: "name" } },
      //   fields: status,
      // },
    
      //   {label: 'Changed By', name: 'edited_by', type: 'selectObject', custom: { extract: { id: 'id', title: 'name' } } , fields: employees},
    ];
  }, [employees]);

  const toggleCsvDialog = useCallback(() => {
    setIsCsvDialog((e) => !e);
  }, [setIsCsvDialog]);

  const handleCsvUpload = useCallback(() => {
    initData();
  }, []);

  return {
    handlePageChange,
    // handleCellClick,
    handleDataSave,
    handleFilterDataChange,
    handleSearchValueChange,
    // handlePreviousPageClick,
    // handleNextPageClick,
    handleRowSize,
    handleSortOrderChange,
    handleDelete,
    handleEdit,
    handleSideToggle,
    handleViewDetails,
    isCalling,
    editData,
    isSidePanel,
    configFilter,
    handleCreate,
    changeEmployeeRoute,
    toggleCsvDialog,
    isCsvDialog,
    handleCsvUpload,
    editData,
    editId
  };
};

export default useAssociatedStateList;
