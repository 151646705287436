export const UPDATE_FORM = "UPDATE_FORM";
export const RESET_FORM = "RESET_FORM";

export const updateForm = (formData) => {
  return (dispatch) => {
    dispatch({
      type: UPDATE_FORM,
      payload: formData,
    });
  };
};

export const resetForm = () => {
  return (dispatch) => {
    dispatch({
      type: RESET_FORM,
    });
  };
};
