import {
  formDataRequest,
  getRequest,
  postRequest,
} from "../libs/AxiosService.util";

export async function serviceCreateProductCat(params) {
  return await formDataRequest("product/categories/create", params);
}
export async function serviceUpdateProductCat(params) {
  return await postRequest("product/categories/update", params);
}

export async function serviceDeleteProductCat(params) {
  return await postRequest("product/categories/delete", params);
}
export async function serviceGetProductCatDetails(params) {
  return await postRequest("product/categories/detail", params);
}
export async function serviceGetProductCat(params) {
  return await postRequest("product/categories", params);
}
export async function serviceCheckProductCatCode(params) {
  return await postRequest("product/categories/check", params);
}
