import React from "react";
import styles from "./Style.module.css";

const LegendColor = () => {
  return (
    <div className={styles.legend}>
      <span className={styles.red}>●{`< 0%`}</span>
      <span className={styles.orange}>● 0 - 70.99%</span>
      <span className={styles.green}>● 71 - 100%</span>
      <span className={styles.purple}>● {`> 100%`}</span>
    </div>
  );
};

export default LegendColor;
