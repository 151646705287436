import { useEffect, useCallback, useState, useMemo, useRef } from "react";
import { useLocation } from "react-router-dom";
import {
  actionFetchTargets,
  actionSetPageTargets,
} from "../../../../../actions/Targets.action";
import { useDispatch, useSelector } from "react-redux";
import { serviceManagementLogsInfo } from "../../../../../services/Management.service";
import { serviceGetList } from "../../../../../services/Common.service";
const initialForm = {
  fy_year: "2024-2025",
  quarter: "Q3",
  brand: "",
  sub_department_id: "",
  product_category: "",
  // hq: ""
};
const useViewTargetsHook = () => {
  const [form, setForm] = useState({ ...initialForm });
  const [isLoading, setIsLoading] = useState(false);
  const location = useLocation();
  const [dateLogs, setDateLogs] = useState({});
  const [listData, setListData] = useState({
    FY_YEAR: [],
    SUB_DEPARTMENTS: [],
  });
  const data = location.state;
  const dispatch = useDispatch();

  const isMountRef = useRef(false);
  const {
    sorting_data: sortingData,
    is_fetching: isFetching,
    query,
    query_data: queryData,
  } = useSelector((state) => state.targets);

   useEffect(() => {
      serviceGetList([ "FY_YEAR", "SUB_DEPARTMENTS",]).then((res) => {
        if (!res.error) {
          setListData(res.data);
        }
      });
    }, []);

  useEffect(() => {
    if (data) {
      setForm({
        month: data.form.month ?? "",
        fy_year: data.form.fy_year ?? "",
        quarter: data.form.quarter ?? "",
      });
    }
  }, [data, location]);

  const changeTextData = useCallback(
    (text, fieldName) => {
      const t = { ...form };
      if (fieldName === "quarter") {
        t[fieldName] = text;
        t["month"] = "";
      } else {
        t[fieldName] = text;
      }
      setForm(t);
    },
    [form, setForm]
  );

  useEffect(() => {
    if (form?.quarter && form?.fy_year ) {
      dispatch(
        actionFetchTargets(1, sortingData, {
          ...form,
        })
      );
    }
    isMountRef.current = true;
  }, [form]);

  useEffect(() => {
    if ( form?.fy_year && form?.quarter) {
      serviceManagementLogsInfo({...form}).then((res) => {
        if (!res.error) {
          setDateLogs(res.data);
        }
      });
    }
  }, [form]);

  const handlePageChange = useCallback((type) => {
    console.log("_handlePageChange", type);
    dispatch(actionSetPageTargets(type, { ...form }));
  }, [form]);

  const queryFilter = useCallback(
    (key, value) => {
      dispatch(
        actionFetchTargets(1, sortingData, {
          query: key == "SEARCH_TEXT" ? value : query,
          query_data: key == "FILTER_DATA" ? value : queryData,
          ...form
        })
      );
    },
    [sortingData, query, queryData, form]
  );

  const handleFilterDataChange = useCallback(
    (value) => {
      console.log("_handleFilterDataChange", value);
      queryFilter("FILTER_DATA", value);
    },
    [queryFilter]
  );

  const handleSearchValueChange = useCallback(
    (value) => {
      queryFilter("SEARCH_TEXT", value);
    },
    [queryFilter]
  );
  const handleSortOrderChange = useCallback(
    (row, order) => {
      console.log(`handleSortOrderChange key:${row} order: ${order}`);
      dispatch(
        actionFetchTargets(
          1,
          { row, order },
          {
            query: query,
            query_data: queryData,
          }
        )
      );
    },
    [query, queryData]
  );

  const handleRowSize = (page) => {
    console.log(page);
  };

  const configFilter = useMemo(() => {
    return [
     
    ];
  }, []);

  return {
    changeTextData,
    form,
    configFilter,
    handleFilterDataChange,
    handlePageChange,
    dateLogs,
    handleSearchValueChange,
    listData
  };
};

export default useViewTargetsHook;
