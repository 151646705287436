import { serviceGetList } from "../services/Common.service";

export const FETCH_EMPLOYEES_REQUEST = "FETCH_EMPLOYEES_REQUEST";
export const FETCH_EMPLOYEES_SUCCESS = "FETCH_EMPLOYEES_SUCCESS";
export const FETCH_EMPLOYEES_FAILURE = "FETCH_EMPLOYEES_FAILURE";

export const fetchEmployees = (params, currentUserId="") => {
  return async (dispatch) => {
    dispatch({ type: FETCH_EMPLOYEES_REQUEST });

    const response = await serviceGetList(["EMPLOYEES"], { ...params });
    if (!response.error) {
      const filteredEmployees = response?.data?.EMPLOYEES?.filter(
        (emp) => emp.id !== currentUserId
      );
      dispatch({ type: FETCH_EMPLOYEES_SUCCESS, payload: filteredEmployees });
   } else {
      dispatch({ type: FETCH_EMPLOYEES_FAILURE, payload: response.error });
    }
  };
};
