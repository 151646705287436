import { useCallback, useEffect, useState, useMemo, useRef } from "react";
import {
  serviceCreateHqs,
  serviceUpdateHqs,
  serviceGetHqsDetails,
  serviceRegionStateZone
} from "../../../../services/RegionsMaster.service";
import SnackbarUtils from "../../../../libs/SnackbarUtils";
import { serviceEmployeeCodeSubmit } from "../../../../services/Employee.service";
import {serviceEditEmployeeVersion} from "../../../../services/EmployeeEdit.service";
import LogUtils from "../../../../libs/LogUtils";
import { useLocation } from "react-router-dom";
import { serviceGetList } from "../../../../services/Common.service";



const initialForm = {
  name: "",
  code: "",
  tbm: "",
  state_id: "",
  zone_id: "",
  is_active: true
};
const useHqCreate = ({ id, isOpen, handleClose, editId, handleSideToggle, detailId,  zoneId}) => {
  const [form, setForm] = useState({ ...initialForm });
  const [editData, setEditData] = useState({});
  const [errorData, setErrorData] = useState({});
  const [data, setData] = useState({});
  const [empId, setEmpId] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const location = useLocation();
      const [listData, setListData] = useState({
        STATES: [],
        ZONES: [],
        HQS: [],
      });
    
       useEffect(() => {
          serviceGetList(["STATES", "ZONES", "HQS"]).then((res) => {
            if (!res.error) {
              setListData(res.data);
            }
          });
        }, []);
  


  const empData = useMemo(
    () => location?.state?.hq || null,
    [location?.state?.hq]
  );


  useEffect(() => {
    if (id) {
      setEmpId(id);
    }
  }, [id]);

  useEffect(() => {
    if (!isOpen) {
      setForm({ ...initialForm });  
     
    }
  }, [isOpen]);


  useEffect(() => {
    if (isOpen && empId) {
      serviceGetHqsDetails({ id: editId }).then((res) => {
        setIsLoading(true);
        if (!res?.error) {
          setData(res?.data?.details);

          const obj = {};
          const data = res?.data?.details;
          setForm({
            ...form,
            name: data?.name,
            code: data?.code,
            tbm: data?.tbm,
            state_id: data?.state?.id,
            is_active: data?.status === "ACTIVE",
            zone_id: data?.zone?.id,
          });
          console.log(data, "ch");
          setEditData({ ...initialForm,
            name: data?.name,
            code: data?.code,
            tbm: data?.tbm,
            state_id: data?.state?.id,
            is_active: data?.status === "ACTIVE",
            zone_id: data?.zone?.id,
          });
        }
        setIsLoading(false);
      });
    }
  }, [isOpen, empId]);

  useEffect(() => {
    if (form?.state_id) {
      // Fetch zones when state_id changes
      serviceRegionStateZone({ stateId: form.state_id }).then((res) => {
        if (!res?.error && res?.data) {
          const zones = res?.data?.zones || [];
          // Update the zone_id field with the first zone or leave it empty
          if (zones.length > 0) {
            setForm((prevForm) => ({
              ...prevForm,
              zone_id: zones[0]?.zone?.id || "", // Set the default zone_id
            }));
          }
        }
      });
    }
  }, [form?.state_id]);

  const checkFormValidation = useCallback(() => {
    const errors = {};
    let required = ["name", "code", "tbm" ];

    required.forEach((val) => {
      if (
        !form?.[val] ||
        (Array.isArray(form?.[val]) && form?.[val].length === 0)
      ) {
        errors[val] = true;
      }
    });

    Object.keys(errors).forEach((key) => {
      if (!errors[key]) {
        delete errors[key];
      }
    });
    return errors;
  }, [form, errorData]);

  const removeError = useCallback(
    (title) => {
      const temp = JSON.parse(JSON.stringify(errorData));
      temp[title] = false;
      setErrorData(temp);
    },
    [setErrorData, errorData]
  );

  const changeTextData = useCallback(
    (text, fieldName) => {
      LogUtils.log(text, fieldName);
      let shouldRemoveError = true;
      const t = { ...form };
      if (fieldName === "tbm") {
        const numericValue = Number(text);
        if (numericValue >= 0 || text === "") { 
          t[fieldName] = text;
        } else {
          return; 
        }
      }  else {
        t[fieldName] = text;
      }
      setForm(t);
      shouldRemoveError && removeError(fieldName);
    },
    [removeError, form, setForm]
  );

  const submitToServer = useCallback(() => {
      if (!isSubmitting) {
        setIsSubmitting(true);
        setIsLoading(true);
  
        let req = serviceCreateHqs;
        if ( editId) {
          req = serviceUpdateHqs({ ...form, id: editId });
        } else {
          req = serviceCreateHqs({ ...form, state_id: detailId, zone_id:  zoneId });
        }
  
        req.then((res) => {
          if (!res.error) {
            
            handleClose();
            window.location.reload();
          } else {
            SnackbarUtils.error(res?.message);
          }
          setIsSubmitting(false);
          setIsLoading(false);
        });
      }
    }, [form, isSubmitting, setIsSubmitting, id]);

  const onBlurHandler = useCallback(
    (type) => {
      if (form?.[type]) {
        changeTextData(form?.[type].trim(), type);
      }
    },
    [changeTextData]
  );

  const handleSubmit = useCallback(
    async (e) => {
      const errors = checkFormValidation();
      if (Object.keys(errors).length > 0) {
        setErrorData(errors);
        return true;
      }
        submitToServer();
    },
    [checkFormValidation, setErrorData, form]
  );



  return {
    isLoading,
    data,
    isSubmitting,
    form,
    changeTextData,
    onBlurHandler,
    removeError,
    errorData,
    empData,
    handleSubmit,
    listData
  };
};

export default useHqCreate;
