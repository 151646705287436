import React, { useMemo } from "react";
import useGradeHook from "./ProductMasterCreateHook";
import { Button, ButtonBase, MenuItem } from "@material-ui/core";
import styles from "./Style.module.css";
import { makeStyles } from "@material-ui/styles";
import CustomTextField from "../../../components/FormFields/TextField/TextField.component";
import history from "../../../libs/history.utils";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import CustomSwitch from "../../../components/FormFields/CustomSwitch";
import useProductMasterCreate from "./ProductMasterCreateHook";
import CustomAutoComplete from "../../../components/FormFields/AutoCompleteText/CustomAutoComplete";
import CustomSelectField from "../../../components/FormFields/SelectField/SelectField.component";

const useStyles = makeStyles((theme) => ({
  iconBtnError: {
    color: theme.palette.error.dark,
  },
  deleteBtn: {
    color: "red",
    // borderBottom: '1px solid red'
  },
}));

const ProductMasterCreateView = ({}) => {
  const {
    form,
    errorData,
    isSubmitting,
    isLoading,
    handleSubmit,
    removeError,
    onBlurHandler,
    changeTextData,
    listData,
    id,
  } = useProductMasterCreate({});
  const classes = useStyles();

  return (
    <div>
      <div className={styles.outerFlex}>
        <div>
          <ButtonBase onClick={() => history.goBack()}>
            <ArrowBackIosIcon fontSize={"small"} />{" "}
            <span>
              <b>{id ? "Update" : "New"} Product</b>
            </span>
          </ButtonBase>
          <div className={styles.newLines} />
        </div>
      </div>

      <div className={"plainPaper"}>
        <div className={"headerFlex"}>
          <h4 className={"infoTitle"}>
            <div className={"heading"}>Product Details</div>
          </h4>
        </div>

        <div className={"formFlex"} id={styles.mobileResponsiveness}>
          <div className={"formGroup"} style={{ display: "flex" }}>
            <CustomTextField
              isError={errorData?.name}
              errorText={errorData?.name}
              label={"Product Name"}
              value={form?.name}
              onTextChange={(text) => {
                changeTextData(text, "name");
              }}
              onBlur={() => {
                onBlurHandler("name");
              }}
            />
          </div>
          <div className={"formGroup"} style={{ display: "flex" }}>
            <CustomTextField
              isError={errorData?.code}
              errorText={errorData?.code}
              label={"Product Code"}
              value={form?.code}
              onTextChange={(text) => {
                changeTextData(text, "code");
              }}
              onBlur={() => {
                onBlurHandler("code");
              }}
            />
          </div>
        </div>
        <div className={"formFlex"} id={styles.mobileResponsiveness}>
          <div className={"formGroup"} style={{ display: "flex" }}>
            <CustomSelectField
              isError={errorData?.division}
              errorText={errorData?.division}
              label={"Division"}
              value={form?.division}
              handleChange={(value) => {
                changeTextData(value, "division");
              }}
            >
              <MenuItem value="ORTHOSWIFT">ORTHOSWIFT</MenuItem>
              <MenuItem value="GYNOSWIFT">GYNOSWIFT</MenuItem>
            </CustomSelectField>
          </div>
          <div className={"formGroup"} style={{ display: "flex" }}></div>
        </div>
        <div className={"formFlex"} id={styles.mobileResponsiveness}>
          <div className={"formGroup"}>
            <CustomAutoComplete
              autoCompleteProps={{
                freeSolo: false,
                getOptionLabel: (option) => {
                  return option?.name;
                },
              }}
              dataset={listData?.BRANDS || []}
              datasetKey={"name"}
              onTextChange={(value) => {
                changeTextData(value, "brand_id");
              }}
              variant={"outlined"}
              label={"Brand"}
              name={"brand_id"}
              isError={errorData?.brand_id}
              errorText={errorData?.brand_id}
              value={form?.brand_id}
            />
          </div>
          <div className={"formGroup"}>
            <CustomAutoComplete
              className={styles.autoCompleteClass}
              autoCompleteProps={{
                freeSolo: false,
                getOptionLabel: (option) => {
                  return option?.name;
                },
              }}
              dataset={listData?.PRODUCT_CATEGORIES || []}
              datasetKey={"name"}
              onTextChange={(value) => {
                changeTextData(value, "product_category_id");
              }}
              variant={"outlined"}
              label={"Product Category"}
              name={"product_category_id"}
              isError={errorData?.product_category_id}
              errorText={errorData?.product_category_id}
              value={form?.product_category_id}
            />
          </div>
        </div>
      </div>

      <div className={"plainPaper"}>
        <div className={"headerFlex"}>
          <h4 className={"infoTitle"}>
            <div className={"heading"}>Status</div>
          </h4>
        </div>

        <div className={"formFlex"}>
          <div className={"formGroup"}>
            <CustomSwitch
              value={form?.is_active}
              handleChange={() => {
                changeTextData(!form?.is_active, "is_active");
              }}
              label={`Active`}
            />
          </div>
        </div>

        <div className={styles.btnCont}>
          <ButtonBase
            disabled={isSubmitting}
            type={"button"}
            onClick={handleSubmit}
            className={styles.createBtn}
          >
            {id ? "Update" : "Create"}
          </ButtonBase>
        </div>
      </div>
    </div>
  );
};

export default ProductMasterCreateView;
