import { UPDATE_FORM, RESET_FORM } from "../actions/SalesForm.action";

const initialState = {
  month: 2,
  fy_year: "2024-2025",
  quarter: "Q3",
  type: "GENERAL",
  start_date: "",
  end_date: "",
};

const SalesformReducer = (state = initialState, action) => {
  switch (action.type) {
    case UPDATE_FORM:
      return { ...state, ...action.payload };

    case RESET_FORM:
      return { ...initialState };

    default:
      return state;
  }
};

export default SalesformReducer;
