/**
 * Created by charnjeetelectrovese@gmail.com on 12/3/2019.
 */
import React, { Component, useCallback, useEffect, useMemo } from "react";
import {
  Button,
  Paper,
  Checkbox,
  IconButton,
  MenuItem,
  ButtonBase,
  CircularProgress,
} from "@material-ui/core";
import classNames from "classnames";
import { connect, useSelector } from "react-redux";
import { Add, EditOutlined, InfoOutlined, PrintOutlined } from "@material-ui/icons";
import PageBox from "../../../components/PageBox/PageBox.component";
import SidePanelComponent from "../../../components/SidePanel/SidePanel.component";
import styles from "./Style.module.css";
import DataTables from "../../../Datatables/Datatable.table";
import Constants from "../../../config/constants";
import FilterComponent from "../../../components/Filter/Filter.component";
import { Edit, RemoveRedEyeOutlined as ViewIcon } from "@material-ui/icons";
import useZone from "./ZoneListHook";
import StatusPill from "../../../components/Status/StatusPill.component";
import CandidateTable from "../../../components/CandidateDataTable/CandidateTable.component";
import ZoneCreate from "./Create/ZoneCreate.view";

const ZoneList = ({}) => {
  const {
    handleSortOrderChange,
    handleRowSize,
    handlePageChange,
    handleDataSave,
    handleDelete,
    handleEdit,
    handleFilterDataChange,
    handleSearchValueChange,
    handleSideToggle,
    handleViewDetails,
    editData,
    isSidePanel,
    handleCreate,
    isCalling,
    configFilter,
    warehouses,
    changeEmployeeRoute,
    toggleCsvDialog,
    isCsvDialog,
    handleCsvUpload,
    editId,
    handleDownload,
    isLoading,
    handleFilterChange,
    selectedFilter,
    filterOptions
  } = useZone({});

  const {
    data,
    all: allData,
    currentPage,
    is_fetching: isFetching,
  } = useSelector((state) => state.regions_master);

  const renderStatus = useCallback((status) => {
    return <StatusPill status={status} />;
  }, []);

  const renderFirstCell = useCallback((obj) => {
    if (obj) {
      const capitalizeFirstLetter = (str) => {
        if (!str) return "N/A"; 
        return str.charAt(0).toUpperCase() + str.slice(1);
      };
      return (
        <div className={styles.firstCellFlex}>
          <div
            className={classNames(styles.firstCellInfo, "openSans")}
         
          >
            <span >{capitalizeFirstLetter(obj.name)}</span>
          </div>
        </div>
      );
    }
    return null;
  }, []);

  const tableStructure = useMemo(() => {
    return [
      {
        key: "name",
        label: "NAME",
        sortable: true,
        render: (value, all) => <div>{renderFirstCell(all)}</div>,
      },
      {
        key: "code",
        label: "CODE",
        sortable: true,
        render: (value, all) => <div>{all?.code}</div>,
      },
      {
        key: "user_id",
        label: "Action",
        ishideMobile: true,
        render: (temp, all) => (
          <div>
             <IconButton
                          className={"tableActionBtn"}
                          color="secondary"
                          disabled={isCalling}
                          onClick={() => {
                            handleViewDetails(all);
                          }}
                        >
                          <InfoOutlined fontSize={"small"} />
                        </IconButton>
            <IconButton
              className={"tableActionBtn"}
              color="secondary"
              disabled={isCalling}
              onClick={() => handleSideToggle(all)}
            >
              <EditOutlined fontSize={"small"} />
            
            </IconButton>
          </div>
        ),
      },
    ];
  }, [renderStatus, renderFirstCell, handleViewDetails, handleEdit, isCalling]);

  const tableData = useMemo(() => {
    const datatableFunctions = {
      // onCellClick: this.handleCellClick,
      // onCellDoubleClick: this.handleCellDoubleClick,
      // onFilterValueChange: this._handleSearchValueChange.bind(this),
      onSortOrderChange: handleSortOrderChange,
      onPageChange: handlePageChange,
      // onRowSelection: this.handleRowSelection,
      onRowSizeChange: handleRowSize,
    };

    const datatable = {
      ...Constants.DATATABLE_PROPERTIES,
      columns: tableStructure,
      data: data,
      count: allData.length,
      page: currentPage,
    };

    return { datatableFunctions, datatable };
  }, [
    allData,
    tableStructure,
    handleSortOrderChange,
    handlePageChange,
    handleRowSize,
    data,
    currentPage,
  ]);

  return (
    <div>
      <PageBox>
        <div className={styles.headerContainer}>
          <div>
            <span className={styles.title}>Zone List</span>
            <div className={styles.newLine} />
          </div>
          <div className={styles.btnWrapperGap}>
          <div className={styles.rightFlex}>
            <ButtonBase
              className={styles.download}
              onClick={handleDownload}
              disabled={isLoading}
            >
              {isLoading ? <CircularProgress size={20} /> : "DOWNLOAD"}
              
            </ButtonBase>
          </div>
            <ButtonBase
              // onClick={toggleCsvDialog}
              onClick={() => handleSideToggle()}
              className={"createBtn"}
              id={styles.btnHideResponsive}
            >
              CREATE 
            <Add fontSize={"small"} className={"plusIcon"}></Add>
            </ButtonBase>
          </div>
        </div>

        <div>
          <FilterComponent
            is_progress={isFetching}
            filters={configFilter}
            dropdown={true}
            selectedFilter={selectedFilter}
            handleFilterChange={handleFilterChange}
            filterOptions={filterOptions}
            handleSearchValueChange={handleSearchValueChange}
            handleFilterDataChange={handleFilterDataChange}
          />
          <div></div>
        </div>
        <SidePanelComponent
          handleToggle={handleSideToggle}
          title={`${editId ? "Edit": "Create"} Zone`}
          open={isSidePanel}
          side={"right"}
        >
          <ZoneCreate
            handleClose={handleSideToggle}
            id={editData}
            // id={id}
            editId={editId}
            isOpen={isSidePanel}

          />
        </SidePanelComponent>
        
      </PageBox>
      <div style={{ width: "100%" }}>
        <DataTables
          {...tableData.datatable}
          {...tableData.datatableFunctions}
        />
      </div>
    </div>
  );
};

export default ZoneList;
