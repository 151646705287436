import { useDispatch, useSelector } from "react-redux";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import historyUtils from "../../../libs/history.utils";
import { serviceGetList } from "../../../services/Common.service";
import RouteName from "../../../routes/Route.name";
import {
  actionFetchProductCat,
  actionSetPageProductCatRequests,
} from "../../../actions/ProductCat.action";

const useProductCatList = () => {
  const [status, setStatus] = useState("");
  const [isCalling, setIsCalling] = useState(false);
  const [editData, setEditData] = useState(null);
  const dispatch = useDispatch();
  const [listData, setListData] = useState({
    LOCATIONS: [],
    GRADES: [],
    DEPARTMENTS: [],
  });
  const isMountRef = useRef(false);
  const {
    sorting_data: sortingData,
    is_fetching: isFetching,
    query,
    query_data: queryData,
  } = useSelector((state) => state.product_cat);

  const initData = useCallback(() => {
    dispatch(
      actionFetchProductCat(1, sortingData, {
        query: isMountRef.current ? query : null,
        query_data: isMountRef.current ? queryData : [],
      })
    );
  }, []);

  useEffect(() => {
    initData();
    isMountRef.current = true;
    serviceGetList(["LOCATIONS", "GRADES", "DEPARTMENTS"]).then((res) => {
      if (!res.error) {
        setListData(res.data);
      }
    });
  }, []);

  const handlePageChange = useCallback((type) => {
    console.log("_handlePageChange", type);
    dispatch(actionSetPageProductCatRequests(type));
  }, []);
  const handleCreate = useCallback(() => {
    historyUtils.push(RouteName.PRODUCT_CAT_CREATE); //+
  }, []);

  const queryFilter = useCallback(
    (key, value) => {
      console.log("_queryFilter", key, value);
      dispatch(
        actionFetchProductCat(1, sortingData, {
          query: key == "SEARCH_TEXT" ? value : query,
          query_data: key == "FILTER_DATA" ? value : queryData,
        })
      );
      // dispatch(actionFetchEmployee(1, sortingData))
    },
    [sortingData, query, queryData]
  );

  const handleFilterDataChange = useCallback(
    (value) => {
      const statusObject = value?.find((item) => item.name === "status");
      const statusValue = statusObject ? statusObject.value : null;
      setStatus(statusValue);
      queryFilter("FILTER_DATA", value);
    },
    [queryFilter, status]
  );

  const handleSearchValueChange = useCallback(
    (value) => {
      console.log("_handleSearchValueChange", value);
      queryFilter("SEARCH_TEXT", value);
    },
    [queryFilter]
  );

  const handleSortOrderChange = useCallback(
    (row, order) => {
      console.log(`handleSortOrderChange key:${row} order: ${order}`);
      // dispatch(actionSetPageProductCatRequests(1));
      dispatch(
        actionFetchProductCat(
          1,
          { row, order },
          {
            query: query,
            query_data: queryData,
          }
        )
      );
    },
    [query, queryData]
  );

  const handleRowSize = (page) => {
    console.log(page);
  };

  const configFilter = useMemo(() => {
    return [
      {
        label: "Location",
        name: "location_id",
        type: "selectObject",
        custom: { extract: { id: "id", title: "name" } },
        fields: listData?.LOCATIONS,
      },
      // {
      //   label: "Grade",
      //   name: "grade_id",
      //   type: "selectObject",
      //   custom: { extract: { id: "id", title: "label" } },
      //   fields: listData?.GRADES,
      // },
      {
        label: "Department",
        name: "department_id",
        type: "selectObject",
        custom: { extract: { id: "id", title: "name" } },
        fields: listData?.DEPARTMENTS,
      },
    ];
  }, [listData]);

   const handleDetails = useCallback((data) => {
      historyUtils.push(`${RouteName.PRODUCT_CAT_UPDATE}${data?.id}`); 
    }, []);

  return {
    handlePageChange,
    handleFilterDataChange,
    handleSearchValueChange,
    handleRowSize,
    handleSortOrderChange,
    isCalling,
    editData,
    configFilter,
    listData,
    handleCreate,
    handleDetails
  };
};

export default useProductCatList;
