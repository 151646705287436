import { postRequest, formDataRequest } from "../libs/AxiosService.util";

export async function serviceManagementDetails(params) {
  return await postRequest("sales/target/management/performance/hq", params);
}

export async function serviceManagementBrandDetails(params) {
  return await postRequest("sales/target/management/performance/brand", params);
}
export async function serviceManagementManagerInfo(params) {
  return await postRequest("sales/target/management/performance/manager", params);
}
export async function serviceManagementEmployeeInfo(params) {
  return await postRequest("sales/target/management/performance/employee", params);
}
export async function serviceManagementTableData(params) {
  return await postRequest("sales/target/management/performance/hq/dashboard/brand/wise", params);
}
export async function serviceManagementLogsInfo(params) {
  return await postRequest("sales/target/date/logs", params);
}
export async function serviceManagementGraphInfo(params) {
  return await postRequest("/sales/target/management/target/achieved", params);
}
