/**
 * Created by charnjeetelectrovese@gmail.com on 4/8/2020.
 */
import {postRequest, formDataRequest} from '../libs/AxiosService.util';

export async function serviceCreateSalesBanner(params) {
    return await formDataRequest('salesbanner/create', params);
}

export async function serviceUpdateSalesBanner(params) {
    return await formDataRequest('salesbanner/update', params);
}

export async function serviceDeleteSalesBanner(params) {
    return await formDataRequest('salesbanner/delete', params);
}


export async function serviceGetSalesBanner (params) {
    return await postRequest('salesbanner', params);
}

export async function serviceSalesBannerDetails (params) {
    return await postRequest('salesbanner/detail', params);
}

export async function serviceGetSalesBannerPriority(params) {
    return await postRequest("salesbanner/update/priority", params);
}







export async function serviceSalesBannerCodeCheck (params) {
    return await postRequest('job/openings/code/exists', params);
}

export async function serviceGetSalesBannerCandidates(params) {
    return await postRequest('job/openings/candidates', params);
}

export async function serviceAddSalesBannerInterviewers(params) {
    return await postRequest('job/openings/interviewers/add', params);
}

export async function serviceGetSalesBannerInterviewers(params) {
    return await postRequest('job/openings/interviewers', params);
}

export async function serviceRejectJobCandidates(params) {
    return await postRequest('job/openings/candidates/reject', params);
}
export async function serviceRejectJobCandidatesEmail(params) {
    return await postRequest('job/openings/send/rejection/email', params);
}

export async function serviceShortlistJobCandidates(params) {
    return await postRequest('job/openings/candidates/shortlist', params);
}

export async function serviceScheduleInterview(params) {
    return await postRequest('job/openings/interview/schedule', params);
}

export async function serviceRequestCVShortlist(params) {
    return await postRequest('job/openings/request/cv/shortlist', params);
}

export async function serviceVacanciesInactive(params) {
    return await postRequest('/vacancies/inactivate', params);
}