import React from "react";
import styles from "./Style.module.css";
import { ButtonBase } from "@material-ui/core";
import { Add, ArrowBackIos } from "@material-ui/icons";
import history from "../../../../libs/history.utils";
import AssociatedHqList from "./component/AssociatedHqList";
import useStateDetail from "./StateDetail.hook";
import SidePanelComponent from "../../../../components/SidePanel/SidePanel.component";
import StateCreate from "../StateCreate/StateCreate.view";
import HqCreate from "../HqCreate/HqCreate.view";

const StateDetail = () => {
  const {data, detailId,  handleSideToggle,
    isSidePanel,
    editData, editId,  zoneId} = useStateDetail();
  return (
    <div>
      <div className={styles.upperFlex}>
        <ButtonBase onClick={() => history.goBack()}>
          <ArrowBackIos fontSize={"small"} />{" "}
          <span>
            <b>State Details</b>
          </span>
        </ButtonBase>
        <div></div>
        <div className={styles.profileHeading}>
          <ButtonBase
            onClick={() => handleSideToggle()}
            className={"createBtn"}
            id={styles.btnHideResponsive}
          >
            ADD HQ
            <Add fontSize={"small"} className={"plusIcon"}></Add>
          </ButtonBase>
        </div>
      </div>
      <div className={styles.newLine} />
     
      <div className={styles.plainPaper}>
        <div className={styles.newContainer}>
          <div className={styles.mainFlex}>
            <div className={styles.left221}>
              <div>
                <div className={styles.key}>
                  <span className={styles.value}>State Name:</span>
                  {data?.name}
                </div>
                <div className={styles.key}>
                  <span className={styles.value}>State Code:</span>
                  {data?.code}
                </div>
              </div>
            </div>
            <div className={styles.left}>
              <div className={styles.key}>
                <span className={styles.value}>Zone Name:</span>
                {data?.zone?.name}
              </div>

              <div className={styles.key}>
                <span className={styles.value}>Associated HQ Count:</span>
                {data?.hqCount}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div>
        <AssociatedHqList detailId={detailId} detailData={data}/>
        <SidePanelComponent
                  handleToggle={handleSideToggle}
                  title={`${editId ? "Edit": "Create"} HQ`}
                  open={isSidePanel}
                  side={"right"}
                >
                  <HqCreate
                    handleClose={handleSideToggle}
                    id={editData}
                    editId={editId}
                    isOpen={isSidePanel}
                    data={data}
                    detailId={detailId}
                    zoneId={data?.zone_id}
                  />
                </SidePanelComponent>
      </div>
    </div>
  );
};

export default StateDetail;
