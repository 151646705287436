import { postRequest, formDataRequest } from "../libs/AxiosService.util";

export async function serviceDoctorsDetails(params) {
  return await postRequest("sales/target/management/performance/hq", params);
}

export async function serviceDoctorsBrandDetails(params) {
  return await postRequest("sales/target/management/performance/brand", params);
}
export async function serviceDoctorsDataInfo(params) {
  return await postRequest("doctors/calls", params);
}
export async function serviceDoctorsEmployeeInfo(params) {
  return await postRequest("sales/target/management/performance/employee", params);
}
export async function serviceDoctorsTableData(params) {
  return await postRequest("sales/target/management/performance/hq/dashboard/brand/wise", params);
}
export async function serviceDoctorsLogsInfo(params) {
  return await postRequest("sales/target/date/logs", params);
}
