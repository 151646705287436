
import { FETCH_EMPLOYEES_FAILURE, FETCH_EMPLOYEES_REQUEST, FETCH_EMPLOYEES_SUCCESS } from "../actions/EmployeeList.action";

const initialState = {
  employees: [],
  loading: false,
  error: null,
};

const employeesListReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_EMPLOYEES_REQUEST:
      return { ...state, loading: true, error: null };

    case FETCH_EMPLOYEES_SUCCESS:
      return { ...state, loading: false, employees: action.payload };

    case FETCH_EMPLOYEES_FAILURE:
      return { ...state, loading: false, error: action.payload };

    default:
      return state;
  }
};

export default employeesListReducer;
