import { postRequest, formDataRequest } from "../libs/AxiosService.util";

export async function serviceCreateAchievedTarget(params) {
  return await formDataRequest("cr/budget/edit/versions/create", params);
}

export async function serviceUpdateAchievedTarget(params) {
  return await postRequest("cr/update", params);
}

export async function serviceDeleteAchievedTarget(params) {
  return await postRequest("cr/delete", params);
}

export async function serviceGetAchievedTarget(params) {
  return await postRequest("sales/target/achieved", params);
}
export async function serviceGetAchievedTargetStatus(params) {
  return await postRequest("sales/target/status", params);
}
export async function serviceAchievedTargetDetail(params) {
  return await postRequest("cr/budget/detail", params);
}
export async function serviceAchievedTargetSend(params) {
  return await postRequest("sales/target/achieved/send", params);
}
export async function serviceAchievedTargetCPCImportVerify(params) {
  return await formDataRequest("sales/target/achieved/import/verify", params);
}
export async function serviceAchievedTargetCPCImportFile(params) {
  return await formDataRequest("sales/target/achieved/import", params);
}
