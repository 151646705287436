import { postRequest, formDataRequest } from "../libs/AxiosService.util";

export async function serviceSalesInfoDetails(params) {
  return await postRequest("sales/target/employee/dashboard", params);
}
export async function serviceSalesStatusDetails(params) {
  return await postRequest("sales/target/employee/dashboard/target/status", params);
}
export async function serviceSalesGraphInfo(params) {
  return await postRequest("sales/target/employee/dashboard/target/achieved", params);
}
export async function serviceSalesTableData(params) {
  return await postRequest("sales/target/employee/dashboard/brand/wise", params);
}
export async function serviceSalesDoctorsDetails(params) {
  return await postRequest("sales/target/employee/dashboard/doctors", params);
}
export async function serviceSalesTargetDetails(params) {
  return await postRequest("sales/target/employee/my", params);
}
export async function serviceSalesCategoryDetail(params) {
  return await postRequest("sales/target/employee/dashboard/category/wise", params);
}
