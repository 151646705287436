import React, { useMemo } from "react";
import CustomSelectField from "../../../../../components/FormFields/SelectField/SelectField.component";
import {
  FormControl,
  FormControlLabel,
  MenuItem,
  Radio,
  RadioGroup,
} from "@material-ui/core";
import styles from "./Style.module.css";
import CustomDatePicker from "../../../../../components/FormFields/DatePicker/CustomDatePicker";

const quarters = {
  Q1: [
    { name: "July", value: 7 },
    { name: "August", value: 8 },
    { name: "September", value: 9 },
  ],
  Q2: [
    { name: "October", value: 10 },
    { name: "November", value: 11 },
    { name: "December", value: 12 },
  ],
  Q3: [
    { name: "January", value: 1 },
    { name: "February", value: 2 },
    { name: "March", value: 3 },
  ],
  Q4: [
    { name: "April", value: 4 },
    { name: "May", value: 5 },
    { name: "June", value: 6 },
  ],
};
function TopBarFields({ form, changeTextData }) {
  const renderMonth = useMemo(() => {
    return form?.quarter ? quarters[form?.quarter] : [];
  }, [form?.quarter]);

  return (
    <div className={styles.container}>
      <div className={styles.radioWrapper}>
        <FormControl component="fieldset">
          <RadioGroup
            value={form?.type}
            onChange={(e) => {
              changeTextData(e?.target?.value, "type");
            }}
            row
            className={styles.radioWrap}
          >
            <FormControlLabel
              value="GENERAL"
              control={<Radio />}
              label="Month Wise"
            />
            <FormControlLabel
              value="CUSTOM"
              control={<Radio />}
              label="Custom"
            />
          </RadioGroup>
        </FormControl>
      </div>
      {form?.type === "GENERAL" ? (
        <div className={styles.filters}>
          <CustomSelectField
            label={"Marketing Year"}
            value={form?.fy_year}
            handleChange={(value) => {
              changeTextData(value, "fy_year");
            }}
          >
            <MenuItem value="2024-2025">2024-2025</MenuItem>
            <MenuItem value="2023-2024">2023-2024</MenuItem>
            <MenuItem value="2022-2023">2022-2023</MenuItem>
            <MenuItem value="2021-2022">2021-2022</MenuItem>
          </CustomSelectField>
          <CustomSelectField
            label={"Quarter"}
            value={form?.quarter}
            handleChange={(value) => {
              changeTextData(value, "quarter");
            }}
          >
            <MenuItem value="Q1">Q1</MenuItem>
            <MenuItem value="Q2">Q2</MenuItem>
            <MenuItem value="Q3">Q3</MenuItem>
            <MenuItem value="Q4">Q4</MenuItem>
          </CustomSelectField>
          <CustomSelectField
            label={"Month"}
            value={form?.month}
            handleChange={(value) => {
              changeTextData(value, "month");
            }}
          >
            {renderMonth?.map((val) => (
              <MenuItem value={val?.value} key={`month_${val?.value}`}>
                {val?.name}
              </MenuItem>
            ))}
          </CustomSelectField>
        </div>
      ) : (
        <div className={styles.filters}>
          <CustomDatePicker
            clearable
            label={"Start Date"}
            onChange={(date) => {
              changeTextData(date, "start_date");
            }}
            value={form?.start_date}
          />
          <CustomDatePicker
            clearable
            label={"End Date"}
            onChange={(date) => {
              changeTextData(date, "end_date");
            }}
            value={form?.end_date}
          />
        </div>
      )}
    </div>
  );
}

export default TopBarFields;
