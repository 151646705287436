import { useDispatch, useSelector } from "react-redux";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import {
  actionFetchRegionsMaster,
  actionSetPageRegionsMaster,
} from "../../../actions/RegionsMaster.action";
import historyUtils from "../../../libs/history.utils";
import RouteName from "../../../routes/Route.name";
import {serviceGetList} from "../../../services/Common.service";
import history from "../../../libs/history.utils";
import {
  serviceRegionExport
} from "../../../services/RegionsMaster.service";
import { useHistory } from "react-router-dom";

const useZoneList = ({}) => {
  const [isSidePanel, setSidePanel] = useState(false);
  const [isCalling, setIsCalling] = useState(false);
  const [editData, setEditData] = useState(null);
  const dispatch = useDispatch();
  const isMountRef = useRef(false);
  const [employees, setEmployees] = useState([]);
  const [isCsvDialog, setIsCsvDialog] = useState(false);
  const [editId, setEditId] = useState(null);
  const [isLoading, setIsLoading] = useState(false); 
  const [selectedFilter, setSelectedFilter] = useState("");
  const history = useHistory();
  const {
    sorting_data: sortingData,
    is_fetching: isFetching,
    query,
    query_data: queryData,
  } = useSelector((state) => state.regions_master);

    // useEffect(() => {
    //     serviceGetList(['EMPLOYEES']).then((res) => {
    //         if (!res.error) {
    //             setEmployees(res?.data?.EMPLOYEES);
    //         }
    //     });
    // }, [])

  useEffect(() => {
    dispatch(
      actionFetchRegionsMaster(1, sortingData, {
        query: isMountRef.current ? query : null,
        query_data: isMountRef.current ? queryData : null,
      })
    );
    isMountRef.current = true;
  }, []);

    const initData = useCallback(() => {
      dispatch(
        actionFetchRegionsMaster(1, sortingData, {
          query: isMountRef.current ? query : null,
          query_data: isMountRef.current ? queryData : null,
        })
      );
    }, []);

  const handlePageChange = useCallback((type) => {
    console.log("_handlePageChange", type);
    dispatch(actionSetPageRegionsMaster(type));
  }, []);

  const handleDataSave = useCallback(
    (data, type) => {
      setSidePanel((e) => !e);
      setEditData(null);
    },
    [setSidePanel, setEditData]
  );
  const changeEmployeeRoute = useCallback((data) => {
    historyUtils.push(`/employees/details/${data?.code}`);
}, []);
  const queryFilter = useCallback(
    (key, value) => {
      console.log("_queryFilter", key, value);
      // dispatch(actionSetPageRegionsMasterRequests(1));
      dispatch(
        actionFetchRegionsMaster(1, sortingData, {
          query: key == "SEARCH_TEXT" ? value : query,
          query_data: key == "FILTER_DATA" ? value : queryData,
        })
      );
      // dispatch(actionFetchRegionsMaster(1, sortingData))
    },
    [sortingData, query, queryData]
  );

  const handleFilterChange = useCallback((e) => {
    const value = e.target.value;
    setSelectedFilter(value);
    history.push(RouteName.REGION_SEARCH, { filterType: value });
}, [history]);

const filterOptions = useMemo(() => [
  { label: "Zone", value: "zone" },
  { label: "State", value: "state" },
  { label: "HQ", value: "hq" }
], []);

  const handleFilterDataChange = useCallback(
    (value) => {
      console.log("_handleFilterDataChange", value);
      queryFilter("FILTER_DATA", value);
    },
    [queryFilter]
  );

  const handleSearchValueChange = useCallback(
    (value) => {
      console.log("_handleSearchValueChange", value);
      queryFilter("SEARCH_TEXT", value);
    },
    [queryFilter]
  );

  const handleSortOrderChange = useCallback(
    (row, order) => {
      console.log(`handleSortOrderChange key:${row} order: ${order}`);
      // dispatch(actionSetPageRegionsMaster(1));
      dispatch(
        actionFetchRegionsMaster(
          1,
          { row, order },
          {
            query: query,
            query_data: queryData,
          }
        )
      );
    },
    [query, queryData]
  );

  const handleRowSize = (page) => {
    console.log(page);
  };

  const handleDelete = useCallback(
    (id) => {
      setSidePanel(false);
      setEditData(null);
    },
    [setEditData, setSidePanel]
  );

  const handleEdit = useCallback(
    (data) => {
      setEditData(data);
      setSidePanel((e) => !e);
    },
    [setEditData, setSidePanel]
  );

  const handleSideToggle = useCallback(
    (data) => {
      setSidePanel((e) => !e);
      if (data) {
        setEditData(data);
        setEditId(data?.id);
        console.log(editId, "id");
       
      }
      console.log(data, "data");

    },
    [setEditData, setSidePanel, setEditId]
  );
console.log(editData, "dd");
  const handleViewDetails = useCallback((data) => {
    historyUtils.push(RouteName.ZONE_DETAILS + data.id); //+data.id
  }, []);

  const handleCreate = useCallback(() => {
    historyUtils.push(RouteName.LOCATIONS_CREATE);
  }, []);

  const configFilter = useMemo(() => {
    return [
    
    ];
  }, [employees]);

  const toggleCsvDialog = useCallback(() => {
    setIsCsvDialog((e) => !e);
  }, [setIsCsvDialog]);

  const handleCsvUpload = useCallback(() => {
    initData();
  }, []);

    const handleDownload = useCallback(() => {
      setIsLoading(true);
      serviceRegionExport().then((res) => {
        if (!res.error) {
          const data = res.data;
          window.open(data, "_blank");
        }
        setIsLoading(false);
      });
    }, );

  return {
    handlePageChange,
    // handleCellClick,
    handleDataSave,
    handleFilterDataChange,
    handleSearchValueChange,
    // handlePreviousPageClick,
    // handleNextPageClick,
    handleRowSize,
    handleSortOrderChange,
    handleDelete,
    handleEdit,
    handleSideToggle,
    handleViewDetails,
    isCalling,
    editData,
    isSidePanel,
    configFilter,
    handleCreate,
    changeEmployeeRoute,
    toggleCsvDialog,
    isCsvDialog,
    handleCsvUpload,
    editData,
    editId,
    handleDownload,
    isLoading,
    handleFilterChange,
    selectedFilter,
    filterOptions
  };
};

export default useZoneList;
