import React, { useMemo } from "react";
import useZoneCreate from "./ZoneCreate.hook";
import WaitingComponent from "../../../../components/Waiting.component";
import styles from "./Style.module.css";
import { ButtonBase, makeStyles } from "@material-ui/core";
import MuiStyle from "../../../../libs/MuiStyle";
import csx from "classnames";
import CustomTextField from "../../../../components/FormFields/TextField/TextField.component";
import CustomToggle from "../../../../components/FormFields/CustomToggle";


const useStyle = makeStyles(MuiStyle);

const ZoneCreate = ({ id, isOpen, handleClose, editId , handleSideToggle}) => {
  const {
    isLoading,
    isSubmitting,
    errorData,
    form,
    changeTextData,
    onBlurHandler,
    handleSubmit ,
    empData,
    data
  } = useZoneCreate({ id, isOpen, handleClose, editId, handleSideToggle });
  const classes = useStyle();

  // if (isLoading) {
  //   return <WaitingComponent />;
  // }

  return (
   
    <div className={styles.panelCont}>
      <div className={"formFlex"}>
        <div className={"formGroup"}>
          <CustomTextField
            isError={errorData?.name}
            errorText={errorData?.name}
            label={"Zone Name"}
            // type="number"
            value={form?.name }
            onTextChange={(text) => {
              changeTextData(text, "name");
            }}
            onBlur={() => {
              onBlurHandler("name");
            }}
          />
        </div>
      </div>
      <div className={"formFlex"}>
        <div className={"formGroup"}>
          <CustomTextField
            isError={errorData?.code}
            errorText={errorData?.code}
            label={"Zone Code"}
            type="number"
            value={form?.code }
            onTextChange={(text) => {
              changeTextData(text, "code");
            }}
            onBlur={() => {
              onBlurHandler("code");
            }}
          />
        </div>
      </div>
      <div className={styles.btnView}>
        <ButtonBase
          disabled={isSubmitting}
          onClick={handleSubmit}
          className={"createBtn"}
        >
          {editId ? "UPDATE" : "CREATE"}
        </ButtonBase>
      </div>
    </div>
  );
};

export default ZoneCreate;
