import {formDataRequest, postRequest} from "../libs/AxiosService.util";



export async function serviceGetDoctorUpload(params) {
    return await formDataRequest('employees/doctor/data/list', params);
}

export async function serviceEditDoctorUpload(params) {
    return await formDataRequest('employee/edit/versions/create', params);
}

export async function serviceGetDoctorUploadDetail(params) {
    return await postRequest('employees/details', params);
}

export async function serviceGetDoctorUploadDetails(params) {
    return await postRequest('employees/records/versions/detail', params);
}
export async function serviceDoctorUploadApprove(params) {
    return await postRequest('employee/edit/versions/approve', params);
}

export async function serviceDoctorUploadReject(params) {
    return await postRequest('employee/edit/versions/reject', params);
}
export async function serviceDoctorUploadImportVerify (params) {
    return await formDataRequest('employees/doctor/import/verify', params);
}
export async function serviceDoctorUploadImportFile(params) {
    return await formDataRequest('employees/doctor/import', params);
}
