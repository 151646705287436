import {
  formDataRequest,
  getRequest,
  postRequest,
} from "../libs/AxiosService.util";

export async function serviceCreateTaxList(params) {
  return await formDataRequest("tax/rebate/review/create", params);
}
export async function serviceTaxFilingTotalInfo(params) {
  return await postRequest("tax/totals", params);
}
export async function serviceTaxFilingCrete(params) {
  return await postRequest("tax/create", params);
}
export async function serviceGetTaxFilings(params) {
  return await postRequest('tax', params);
}
export async function serviceGetEmpTaxFilingDetails(params) {
  return await postRequest('tax/details', params);
}
export async function serviceGetTaxReviews(params) {
  return await postRequest('tax/review', params);
}
export async function serviceGetTaxReviewDetails(params) {
  return await postRequest('tax/review/details', params);
}
export async function serviceTaxFilingDraftedDetail(params) {
  return await postRequest("tax/drafted", params);
}
export async function serviceTaxFilingEmpSalaryDetail(params) {
  return await postRequest("tax/employee/salary", params);
}
export async function serviceTaxFillingApprove(params) {
  return await postRequest("tax/review/accept", params);
}
export async function serviceTaxFillingReject(params) {
  return await postRequest("tax/review/reject", params);
}
export async function serviceTaxImportVerify (params) {
  return await formDataRequest('tax/import/verify', params);
}
export async function serviceTaxImportFile(params) {
  return await formDataRequest('tax/import', params);
}
export async function serviceGetTaxSubmissionData (params) {
  return await postRequest('tax/submission', params);
}
export async function serviceTaxStatusChange(params) {
  return await postRequest('tax/status/create', params);
}
export async function serviceGetTaxStatus(params) {
  return await postRequest('tax/status', params);
}
export async function serviceGetTaxFilingStatus(params) {
  return await postRequest('tax/window/status', params);
}