import { useCallback, useEffect, useState, useMemo, useRef } from "react";
import {
  serviceCreateRegionsMaster,
  serviceUpdateRegionsMaster,
  serviceGetRegionsMasterDetails
} from "../../../../services/RegionsMaster.service";
import SnackbarUtils from "../../../../libs/SnackbarUtils";
import { serviceEmployeeCodeSubmit } from "../../../../services/Employee.service";
import {serviceEditEmployeeVersion} from "../../../../services/EmployeeEdit.service";
import LogUtils from "../../../../libs/LogUtils";
import { useLocation } from "react-router-dom";



const initialForm = {
  name: "",
  code: "",
  "is_active": true
};
const useZoneCreate = ({ id, isOpen, handleClose, editId, handleSideToggle }) => {
  const [form, setForm] = useState({ ...initialForm });
  const [editData, setEditData] = useState({});
  const [errorData, setErrorData] = useState({});
  const [data, setData] = useState({});
  const [empId, setEmpId] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const location = useLocation();


  const empData = useMemo(
    () => location?.state?.regions_master || null,
    [location?.state?.regions_master]
  );


  useEffect(() => {
    if (id) {
      setEmpId(id);
    }
  }, [id]);
  
  useEffect(() => {
    if (!isOpen) {
      setForm({ ...initialForm });  
     
    }
  }, [isOpen]);

  useEffect(() => {
    if (isOpen && empId) {
      serviceGetRegionsMasterDetails({ id: editId }).then((res) => {
        setIsLoading(true);
        if (!res?.error) {
          setData(res?.data?.details);

          const obj = {};
          const data = res?.data?.details;
          setForm({
            ...form,
            name: data?.name,
            code: data?.code
          });
          console.log(data, "ch");
          setEditData({ ...initialForm,
            name: data?.name,
            code: data?.code
          });
        }
        setIsLoading(false);
      });
    }
  }, [isOpen, empId]);

  const checkFormValidation = useCallback(() => {
    const errors = {};
    let required = ["name", "code"];

    required.forEach((val) => {
      if (
        !form?.[val] ||
        (Array.isArray(form?.[val]) && form?.[val].length === 0)
      ) {
        errors[val] = true;
      }
    });

    Object.keys(errors).forEach((key) => {
      if (!errors[key]) {
        delete errors[key];
      }
    });
    return errors;
  }, [form, errorData]);

  const removeError = useCallback(
    (title) => {
      const temp = JSON.parse(JSON.stringify(errorData));
      temp[title] = false;
      setErrorData(temp);
    },
    [setErrorData, errorData]
  );

  const changeTextData = useCallback(
    (text, fieldName) => {
      LogUtils.log(text, fieldName);
      let shouldRemoveError = true;
      const t = { ...form };
      if (fieldName === "code") {
        if (text >= 0) {
          t[fieldName] = text;
        }
      } else {
        t[fieldName] = text;
      }
      setForm(t);
      shouldRemoveError && removeError(fieldName);
    },
    [removeError, form, setForm]
  );
 

  const submitToServer = useCallback(() => {
      if (!isSubmitting) {
        setIsSubmitting(true);
        setIsLoading(true);
  
        let req = serviceCreateRegionsMaster;
        // if (id) {
        //   form.id = editId,
        //   req = serviceUpdateRegionsMaster;
        // }
        if ( editId) {
          req = serviceUpdateRegionsMaster({ ...form, id: editId });
        } else {
          req = serviceCreateRegionsMaster({ ...form });
        }
  
        req.then((res) => {
          if (!res.error) {
            
            handleClose();
            window.location.reload();
          } else {
            SnackbarUtils.error(res?.message);
          }
          setIsSubmitting(false);
          setIsLoading(false);
        });
      }
    }, [form, isSubmitting, setIsSubmitting, id]);

  const onBlurHandler = useCallback(
    (type) => {
      if (form?.[type]) {
        changeTextData(form?.[type].trim(), type);
      }
    },
    [changeTextData]
  );

  const handleSubmit = useCallback(
    async (e) => {
      const errors = checkFormValidation();
      if (Object.keys(errors).length > 0) {
        setErrorData(errors);
        return true;
      }
        submitToServer();
    },
    [checkFormValidation, setErrorData, form]
  );



  return {
    isLoading,
    data,
    isSubmitting,
    form,
    changeTextData,
    onBlurHandler,
    removeError,
    errorData,
    empData,
    handleSubmit 
  };
};

export default useZoneCreate;
