import store from "../store";
import Constants from "../config/constants";
import { serviceGetBrandMaster } from "../services/BrandMaster.service";

export const FETCH_INIT = "FETCH_INIT_BRAND";
export const FETCHED = "FETCHED_BRAND";
export const FETCHED_FAIL = "FETCHED_FAIL_BRAND";
export const FETCHED_FILTER = "FETCHED_FILTER_BRAND";
export const FETCH_NEXT = "FETCH_NEXT_BRAND";
export const FILTER = "FILTER_BRAND";
export const RESET_FILTER = "RESET_FILTER_BRAND";
export const SET_SORTING = "SET_SORTING_BRAND";
export const SET_FILTER = "SET_FILTER_BRAND";
export const SET_PAGE = "SET_PAGE_BRAND";
export const CHANGE_PAGE = "CHANGE_PAGE_BRAND";
export const CHANGE_STATUS = "CHANGE_STATE_BRAND";
export const SET_SERVER_PAGE = "SET_SERVER_PAGE_BRAND";
export const CREATE_DATA = "CREATE_BRAND";
export const UPDATE_DATA = "UPDATE_BRAND";
export const DELETE_ITEM = "DELETE_BRAND";
export const GET_EMPLOYEE_DATA = "GET_BRAND_DATA";

export function actionFetchBrandMaster(index = 1, sorting = {}, filter = {}) {
  const request = serviceGetBrandMaster({
    index,
    row: sorting.row,
    order: sorting.order,
    ...filter,
  });
  return (dispatch) => {
    dispatch({ type: FETCH_INIT, payload: null });
    request.then((data) => {
      dispatch({ type: SET_FILTER, payload: filter });
      dispatch({ type: SET_SORTING, payload: sorting });
      if (!data.error) {
        dispatch({
          type: FETCHED,
          payload: {
            data: data.data,
            total: data?.data?.total,
            page: index,
          },
        });
        dispatch({ type: SET_SERVER_PAGE, payload: index });
        if (index == 1) {
          dispatch({ type: CHANGE_PAGE, payload: index - 1 });
        }
      } else {
        dispatch({ type: FETCHED_FAIL, payload: null });
      }
    });
  };
}

export function actionChangePageBrandMasterRequests(page) {
  return (dispatch) => {
    dispatch({ type: CHANGE_PAGE, payload: page });
  };
}

export function actionFilterBrandMasterRequests(value) {
  const request = null;
  return (dispatch) => {
    dispatch({ type: FETCH_INIT, payload: null });
    request.then((data) => {
      dispatch({ type: FILTER, payload: data });
      dispatch({ type: FETCHED, payload: null }); //dispatch function
    });
  };
}

export function actionResetFilterBrandMasterRequests() {
  return {
    type: RESET_FILTER,
    payload: null,
  };
}

export function actionSetPageBrandMasterRequests(page) {
  const stateData = store.getState().brand_master;
  const currentPage = stateData.currentPage;
  const totalLength = stateData.all.length;
  const sortingData = stateData.sorting_data;
  const query = stateData.query;
  const queryData = stateData.query_data;
  const serverPage = stateData.serverPage;

  if (totalLength <= (page + 1) * Constants.DEFAULT_PAGE_VALUE) {
    store.dispatch(
      actionFetchBrandMaster(serverPage + 1, sortingData, {
        query,
        query_data: queryData,
      })
    );
    // this.props.fetchNextUsers(this.props.serverPage + 1, this.props.sorting_data.row, this.props.sorting_data.order, { query: this.props.query, query_data: this.props.query_data });
  }

  return {
    type: CHANGE_PAGE,
    payload: page,
  };
}
