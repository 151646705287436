import { postRequest } from "../libs/AxiosService.util";

export async function serviceGetRegionMaster(params) {
  return await postRequest("regions", params);
}
export async function serviceCreateRegionMaster(params) {
  return await postRequest("regions/create", params);
}
export async function serviceUpdateRegionMaster(params) {
  return await postRequest("regions/update", params);
}
export async function serviceGetRegionMasterDetails(params) {
  return await postRequest("regions/detail", params);
}
export async function serviceIsExistRegionMaster(params) {
  return await postRequest("regions/check", params);
}


