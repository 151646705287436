import { postRequest, formDataRequest } from "../libs/AxiosService.util";

export async function serviceCreateSalesAchievementReport(params) {
  return await formDataRequest("cr/budget/edit/versions/create", params);
}

export async function serviceUpdateSalesAchievementReport(params) {
  return await postRequest("cr/update", params);
}

export async function serviceDeleteSalesAchievementReport(params) {
  return await postRequest("cr/delete", params);
}

export async function serviceGetSalesAchievementReport(params) {
  return await postRequest("sales/target/employee", params);
}
export async function serviceGetSalesAchievementReportStatus(params) {
  return await postRequest("sales/target/status", params);
}
export async function serviceSalesAchievementReportDetail(params) {
  return await postRequest("cr/budget/detail", params);
}
export async function serviceSalesAchievementReportSend(params) {
  return await postRequest("sales/target/send", params);
}
export async function serviceSalesAchievementReportCPCImportVerify(params) {
  return await formDataRequest("sales/target/import/verify", params);
}
export async function serviceSalesAchievementReportCPCImportFile(params) {
  return await formDataRequest("sales/target/import", params);
}
export async function serviceExportSalesAchievementReport(params) {
  return await postRequest('sales/target/employee/export', params);
}