import store from "../store";
import Constants from "../config/constants";
import { serviceGetProductCat } from "../services/ProductCat.service";

export const FETCH_INIT = "FETCH_INIT_PRODUCT_CAT";
export const FETCHED = "FETCHED_PRODUCT_CAT";
export const FETCHED_FAIL = "FETCHED_FAIL_PRODUCT_CAT";
export const FETCHED_FILTER = "FETCHED_FILTER_PRODUCT_CAT";
export const FETCH_NEXT = "FETCH_NEXT_PRODUCT_CAT";
export const FILTER = "FILTER_PRODUCT_CAT";
export const RESET_FILTER = "RESET_FILTER_PRODUCT_CAT";
export const SET_SORTING = "SET_SORTING_PRODUCT_CAT";
export const SET_FILTER = "SET_FILTER_PRODUCT_CAT";
export const SET_PAGE = "SET_PAGE_PRODUCT_CAT";
export const CHANGE_PAGE = "CHANGE_PAGE_PRODUCT_CAT";
export const CHANGE_STATUS = "CHANGE_STATE_PRODUCT_CAT";
export const SET_SERVER_PAGE = "SET_SERVER_PAGE_PRODUCT_CAT";
export const CREATE_DATA = "CREATE_PRODUCT_CAT";
export const UPDATE_DATA = "UPDATE_PRODUCT_CAT";
export const DELETE_ITEM = "DELETE_PRODUCT_CAT";
export const GET_EMPLOYEE_DATA = "GET_PRODUCT_CAT_DATA";

export function actionFetchProductCat(index = 1, sorting = {}, filter = {}) {
  const request = serviceGetProductCat({
    index,
    row: sorting.row,
    order: sorting.order,
    ...filter,
  });
  return (dispatch) => {
    dispatch({ type: FETCH_INIT, payload: null });
    request.then((data) => {
      dispatch({ type: SET_FILTER, payload: filter });
      dispatch({ type: SET_SORTING, payload: sorting });
      if (!data.error) {
        dispatch({
          type: FETCHED,
          payload: {
            data: data.data,
            total: data?.data?.total,
            page: index,
          },
        });
        dispatch({ type: SET_SERVER_PAGE, payload: index });
        if (index == 1) {
          dispatch({ type: CHANGE_PAGE, payload: index - 1 });
        }
      } else {
        dispatch({ type: FETCHED_FAIL, payload: null });
      }
    });
  };
}

export function actionChangePageProductCatRequests(page) {
  return (dispatch) => {
    dispatch({ type: CHANGE_PAGE, payload: page });
  };
}

export function actionFilterProductCatRequests(value) {
  const request = null;
  return (dispatch) => {
    dispatch({ type: FETCH_INIT, payload: null });
    request.then((data) => {
      dispatch({ type: FILTER, payload: data });
      dispatch({ type: FETCHED, payload: null }); //dispatch function
    });
  };
}

export function actionResetFilterProductCatRequests() {
  return {
    type: RESET_FILTER,
    payload: null,
  };
}

export function actionSetPageProductCatRequests(page) {
  const stateData = store.getState().product_cat;
  const currentPage = stateData.currentPage;
  const totalLength = stateData.all.length;
  const sortingData = stateData.sorting_data;
  const query = stateData.query;
  const queryData = stateData.query_data;
  const serverPage = stateData.serverPage;

  if (totalLength <= (page + 1) * Constants.DEFAULT_PAGE_VALUE) {
    store.dispatch(
      actionFetchProductCat(serverPage + 1, sortingData, {
        query,
        query_data: queryData,
      })
    );
    // this.props.fetchNextUsers(this.props.serverPage + 1, this.props.sorting_data.row, this.props.sorting_data.order, { query: this.props.query, query_data: this.props.query_data });
  }

  return {
    type: CHANGE_PAGE,
    payload: page,
  };
}
