/**
 * Created by charnjeetelectrovese@gmail.com on 12/3/2019.
 */
import React, { Component, useCallback, useEffect, useMemo } from "react";
import {
  Button,
  Paper,
  Checkbox,
  IconButton,
  MenuItem,
  ButtonBase,
  CircularProgress,
} from "@material-ui/core";
import classNames from "classnames";
import { connect, useSelector } from "react-redux";
import {
  Add,
  ArrowBackIos,
  EditOutlined,
  InfoOutlined,
  PrintOutlined,
} from "@material-ui/icons";
import PageBox from "../../../components/PageBox/PageBox.component";
import SidePanelComponent from "../../../components/SidePanel/SidePanel.component";
import styles from "./Style.module.css";
import DataTables from "../../../Datatables/Datatable.table";
import Constants from "../../../config/constants";
import FilterComponent from "../../../components/Filter/Filter.component";
import { Edit, RemoveRedEyeOutlined as ViewIcon } from "@material-ui/icons";
import useRegionSearch from "./RegionSearch.hook";
import StatusPill from "../../../components/Status/StatusPill.component";
import CandidateTable from "../../../components/CandidateDataTable/CandidateTable.component";
import ZoneCreate from "../Zone/Create/ZoneCreate.view";
import StateCreate from "../Zone/StateCreate/StateCreate.view";
import HqCreate from "../Zone/HqCreate/HqCreate.view";
import history from "../../../libs/history.utils";

const RegionSearch = ({}) => {
  const {
    handleSortOrderChange,
    handleRowSize,
    handlePageChange,
    handleDataSave,
    handleDelete,
    handleEdit,
    handleFilterDataChange,
    handleSearchValueChange,
    handleSideToggle,
    handleViewDetails,
    editData,
    isSidePanel,
    handleCreate,
    isCalling,
    configFilter,
    warehouses,
    changeEmployeeRoute,
    toggleCsvDialog,
    isCsvDialog,
    handleCsvUpload,
    editId,
    handleDownload,
    isLoading,
    handleFilterChange,
    selectedFilter,
    filterOptions,
    filterType,
    handleViewStateDetails
  } = useRegionSearch({});

  const {
    data,
    all: allData,
    currentPage,
    is_fetching: isFetching,
  } = useSelector((state) => state.regions_search);

  const renderStatus = useCallback((status) => {
    return <StatusPill status={status} />;
  }, []);

  const renderFirstCell = useCallback((obj) => {
    if (obj) {
      return (
        <div className={styles.firstCellFlex}>
          <div className={classNames(styles.firstCellInfo, "openSans")}>
            <span>{obj?.name}</span>
          </div>
        </div>
      );
    }
    return null;
  }, []);

  const tableStructure = useMemo(() => {
    if (filterType === "state") {
      return [
        {
          key: "name",
          label: "ZONE NAME",
          sortable: false,
          render: (value, all) => <div>{all?.zone?.name}</div>,
        },
        {
          key: "code",
          label: "ZONE CODE",
          sortable: false,
          render: (value, all) => <div>{all?.zone?.code}</div>,
        },
        {
          key: "name",
          label: "STATE NAME",
          sortable: false,
          render: (value, all) => <div>{renderFirstCell(all)}</div>,
        },
        {
          key: "code",
          label: "STATE CODE",
          sortable: false,
          render: (value, all) => <div>{all?.code}</div>,
        },
        {
          key: "status",
          label: "Status",
          sortable: false,
          render: (temp, all) => <div>{renderStatus(all.status)}</div>,
        },
        {
          key: "user_id",
          label: "Action",
          ishideMobile: false,
          render: (temp, all) => (
            <div>
               <IconButton
                            className={"tableActionBtn"}
                            color="secondary"
                            disabled={isCalling}
                            onClick={() => {
                              handleViewStateDetails(all);
                            }}
                          >
                            <InfoOutlined fontSize={"small"} />
                          </IconButton>
              <IconButton
                className={"tableActionBtn"}
                color="secondary"
                disabled={isCalling}
                onClick={() => handleSideToggle(all)}
              >
                <EditOutlined fontSize={"small"} />
              
              </IconButton>
            </div>
          ),
        },
      ];
  } else if(filterType === "hq"){
    return [
      {
        key: "name",
        label: "ZONE NAME",
        sortable: false,
        render: (value, all) => <div>{all?.zone?.name}</div>,
      },
      {
        key: "code",
        label: "ZONE CODE",
        sortable: false,
        render: (value, all) => <div>{all?.zone?.code}</div>,
      },
      {
        key: "name",
        label: "STATE NAME",
        sortable: false,
        render: (value, all) => <div>{all?.state?.name}</div>,
      },
      {
        key: "code",
        label: "STATE CODE",
        sortable: false,
        render: (value, all) => <div>{all?.state?.code}</div>,
      },
      {
        key: "name",
        label: "HQ NAME",
        sortable: false,
        render: (value, all) => <div>{renderFirstCell(all)}</div>,
      },
      {
        key: "code",
        label: "HQ CODE",
        sortable: false,
        render: (value, all) => <div>{all?.code}</div>,
      },
      {
        key: "manpower_count",
        label: "MANPOWER COUNT",
        sortable: false,
        render: (value, all) => <div>{all?.tbm}</div>,
      },
      {
        key: "status",
        label: "Status",
        sortable: false,
        render: (temp, all) => <div>{renderStatus(all.status)}</div>,
      },
      {
        key: "user_id",
        label: "Action",
        ishideMobile: false,
        render: (temp, all) => (
          <div>
            
            <IconButton
              className={"tableActionBtn"}
              color="secondary"
              disabled={isCalling}
              onClick={() => handleSideToggle(all)}
            >
              <EditOutlined fontSize={"small"} />
            
            </IconButton>
          </div>
        ),
      },
    ];
  } else {
    return [
      {
        key: "name",
        label: "NAME",
        sortable: false,
        render: (value, all) => <div>{renderFirstCell(all)}</div>,
      },
      {
        key: "code",
        label: "CODE",
        sortable: false,
        render: (value, all) => <div>{all?.code}</div>,
      },
      {
        key: "status",
        label: "Status",
        sortable: false,
        render: (temp, all) => <div>{renderStatus(all.status)}</div>,
      },
      {
        key: "user_id",
        label: "Action",
        ishideMobile: false,
        render: (temp, all) => (
          <div>
            <IconButton
              className={"tableActionBtn"}
              color="secondary"
              disabled={isCalling}
              onClick={() => {
                handleViewDetails(all);
              }}
            >
              <InfoOutlined fontSize={"small"} />
            </IconButton>
            <IconButton
              className={"tableActionBtn"}
              color="secondary"
              disabled={isCalling}
              onClick={() => handleSideToggle(all)}
            >
              <EditOutlined fontSize={"small"} />
            </IconButton>
          </div>
        ),
      },
    ];
  }
  }, [renderStatus, renderFirstCell, handleViewDetails, handleViewStateDetails, handleEdit, isCalling, filterType]);

  const tableData = useMemo(() => {
    const datatableFunctions = {
      // onCellClick: this.handleCellClick,
      // onCellDoubleClick: this.handleCellDoubleClick,
      // onFilterValueChange: this._handleSearchValueChange.bind(this),
      onSortOrderChange: handleSortOrderChange,
      onPageChange: handlePageChange,
      // onRowSelection: this.handleRowSelection,
      onRowSizeChange: handleRowSize,
    };

    const datatable = {
      ...Constants.DATATABLE_PROPERTIES,
      columns: tableStructure,
      data: data,
      count: allData.length,
      page: currentPage,
    };

    return { datatableFunctions, datatable };
  }, [
    allData,
    tableStructure,
    handleSortOrderChange,
    handlePageChange,
    handleRowSize,
    data,
    currentPage,
  ]);

  return (
    <div>
      <PageBox>
        <div className={styles.headerContainer}>
          <div>
            <ButtonBase onClick={() => history.goBack()}>
              <ArrowBackIos fontSize={"small"} />{" "}
              <span>
                <b>Search Results</b>
              </span>
            </ButtonBase>

            <div className={styles.newLine} />
          </div>
        </div>

        <div>
          <FilterComponent
            is_progress={isFetching}
            filters={configFilter}
            dropdown={true}
            selectedFilter={selectedFilter}
            handleFilterChange={handleFilterChange}
            filterOptions={filterOptions}
            handleSearchValueChange={handleSearchValueChange}
            handleFilterDataChange={handleFilterDataChange}
          />
          <div></div>
        </div>
        <SidePanelComponent
          handleToggle={handleSideToggle}
          title={`${editId ? "Edit" : "Create"} ${
            filterType === "state" ? "State" : filterType === "hq" ? "HQ" : "Zone"
          }`}
          open={isSidePanel}
          side={"right"}
        >
        {filterType === "state" ? (
    <StateCreate
      handleClose={handleSideToggle}
      id={editData}
      editId={editId}
      isOpen={isSidePanel}
      // detailId={id}
    />
  ): filterType === "hq" ? (
    <HqCreate
      handleClose={handleSideToggle}
      id={editData}
      editId={editId}
      isOpen={isSidePanel}
      // data={detailData}
      // detailId={id}
      zoneId={editData?.zone_id}
    />
  )  : (
    <ZoneCreate
      handleClose={handleSideToggle}
      id={editData}
      editId={editId}
      isOpen={isSidePanel}
    />
  )}
        </SidePanelComponent>
      </PageBox>
      <div style={{ width: "100%" }}>
        <DataTables
          {...tableData.datatable}
          {...tableData.datatableFunctions}
        />
      </div>
    </div>
  );
};

export default RegionSearch;
