import React, {
  Component,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import { ButtonBase, capitalize, MenuItem } from "@material-ui/core";
import classNames from "classnames";
import { useSelector } from "react-redux";
import PageBox from "../../../../../components/PageBox/PageBox.component";
import styles from "./Style.module.css";
import Constants from "../../../../../config/constants";
import StatusPill from "../../../../../components/Status/StatusPill.component";
import Datatables from "../../../../../components/Datatables/datatables";
import useViewTargetsHook from "./ViewTargets.hook";
import history from "../../../../../libs/history.utils";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import { getBackgroundColor } from "../../../../../helper/helper";
import LegendColor from "../Legend/Legend.view";
import FilterComponent from "../../../../../components/Filter/Filter.component";
import TopBarFields from "../../../../Management/Dashboard/component/TopBarFields/TopBarFields.view";
import CustomSelectField from "../../../../../components/FormFields/SelectField/SelectField.component";

const ViewTargetsView = () => {
  const {
    handleSortOrderChange,
    handleRowSize,
    handlePageChange,
    handleDetails,
    isCalling,
    changeTextData,
    form,
    dateLogs,
    handleSearchValueChange,
    configFilter,
    handleFilterDataChange,
    listData
  } = useViewTargetsHook({});

  const {
    data,
    all: allData,
    currentPage,
    is_fetching: isFetching,
  } = useSelector((state) => state.targets);

  const renderFirstCell = useCallback((obj) => {
    if (obj) {
      return (
        <div className={styles.firstCellFlex}>
          <div className={classNames(styles.firstCellInfo, "openSans")}>
            <span className={styles.productName}>
              <strong>{obj?.candidate?.name}</strong>
            </span>

            <br />
          </div>
        </div>
      );
    }
    return null;
  }, []);

  const tableStructure = useMemo(() => {
    return [
      {
        key: "hq",
        label: "HQ",
        sortable: false,
        render: (value, all) => <div>{
          // capitalize
          (all?.sub_department?.name)}</div>,
      },
      {
        key: "brand",
        label: "BRAND",
        sortable: false,
        render: (value, all) => <div>{
          // capitalize
          (all?.brand)}</div>,
      },
      {
        key: "product_category",
        label: "PRODUCT CATEGORY",
        sortable: false,
        render: (value, all) => <div>{
          // capitalize
          (all?.product_category)}</div>,
      },
      {
        key: "target_month1",
        label: "JUL",
        sortable: false,
        render: (value, all) => <div>{all?.target_month1}</div>,
      },
      {
        key: "target_month2",
        label: "AUG",
        sortable: false,
        render: (value, all) => <div>{all?.target_month2 }</div>,
      },
      {
        key: "target_month3",
        label: "SEP",
        sortable: false,
        render: (value, all) => <div>{all?.target_month3}</div>,
      },
      {
        key: "total_target",
        label: "TOTAL TARGET",
        sortable: false,
        style: { width: "18%" },
        render: (temp, all) => <div>{all.total_target}</div>,
      },
      {
        key: "primary_sales_acheived",
        label: "PRIMARY SALES ACHIEVED",
        sortable: false,
        render: (temp, all) => <div>{all?.primary_achieved}</div>,
      },
      {
        key: "secondary_achieved",
        label: "SECONDARY SALES ACHIEVED",
        sortable: false,
        render: (temp, all) => <div>{all?.secondary_achieved}</div>,
      },
      {
        key: "p_s_ratio",
        label: "P/S RATIO",
        sortable: false,
        render: (temp, all) => <div>{all?.p_s_ratio}</div>,
      },
      {
        key: "achieved_pct",
        label: "ACHIEVED%",
        sortable: false,
        render: (temp, all) => (
          <div
            className={styles.bgColor}
            style={{ backgroundColor: getBackgroundColor(all?.achieved_pct) }}
          >
            {all?.achieved_pct}%
          </div>
        ),
      },
    ];
  }, [renderFirstCell, handleDetails, isCalling]);

  const tableData = useMemo(() => {
    const datatableFunctions = {
      onSortOrderChange: handleSortOrderChange,
      onPageChange: handlePageChange,
      onRowSizeChange: handleRowSize,
    };

    const datatable = {
      ...Constants.DATATABLE_PROPERTIES,
      columns: tableStructure,
      data: data,
      count: allData.length,
      page: currentPage,
    };

    return { datatableFunctions, datatable };
  }, [
    allData,
    tableStructure,
    handleSortOrderChange,
    handlePageChange,
    handleRowSize,
    data,
    currentPage,
  ]);

  return (
    <div>
      <PageBox>
        <div className={styles.headerContainer}>
          <ButtonBase onClick={() => history.goBack()}>
            <ArrowBackIosIcon fontSize={"small"} className={styles.backIcon} />
          </ButtonBase>
          <div>
            <span className={styles.title}>My Targets</span>
            <div className={styles.newLine} />
          </div>
        </div>
        {/* <TopBarFields form={form} changeTextData={changeTextData} /> */}
        <div className={"formFlex"}>
        <div className={"formGroup"}>
        <CustomSelectField
          label={"Marketing Year"}
          value={form?.fy_year}
          handleChange={(value) => {
            changeTextData(value, "fy_year");
          }}
        >
          <MenuItem value="2024-2025">2024-2025</MenuItem>
        </CustomSelectField>

          </div>
          <div className={"formGroup"}>
          <CustomSelectField
          label={"Quarter"}
          value={form?.quarter}
          handleChange={(value) => {
            changeTextData(value, "quarter");
          }}
        >
          <MenuItem value="Q1">Q1</MenuItem>
          <MenuItem value="Q2">Q2</MenuItem>
          <MenuItem value="Q3">Q3</MenuItem>
          <MenuItem value="Q4">Q4</MenuItem>
        </CustomSelectField>
          </div>
          <div className={"formGroup"}>
          <CustomSelectField
          label={"HQ"}
          value={form?.sub_department_id}
          handleChange={(value) => {
            changeTextData(value, "sub_department_id");
          }}
        >
          {/* <MenuItem value="ALL">All</MenuItem> */}
          {listData?.SUB_DEPARTMENTS?.map((item, index) => (
                          <MenuItem key={`${index}`} value={item?.id}>
                            {item?.label}
                          </MenuItem>
                        ))}
         
        </CustomSelectField>
          </div>
          <div className={"formGroup"}>
          <CustomSelectField
          label={"Brand"}
          value={form?.brand}
          handleChange={(value) => {
            changeTextData(value, "brand");
          }}
        >
          <MenuItem value="ALL">All</MenuItem>
        </CustomSelectField>
          </div>
          <div className={"formGroup"}>
          <CustomSelectField
          label={"Product Category"}
          value={form?.product_category}
          handleChange={(value) => {
            changeTextData(value, "product_category");
          }}
        >
          <MenuItem value="ALL">All</MenuItem>
        </CustomSelectField>
          </div>
          </div>
        <div className={styles.legendContainer}>
        <div className={styles.leftCon}>
            <div className={styles.des}>
              Primary Last Updated: <span>{dateLogs?.primary_update_date || 'N/A'}</span>
            </div>
            <div className={styles.des}>
              Secondary Last Updated:<span>{dateLogs?.secondary_update_date || 'N/A'}</span>
            </div>
          </div>

          <div className={styles.rightCon}>
            <LegendColor />
          </div>
        </div>
        <FilterComponent
            is_progress={isFetching}
            filters={configFilter}
            handleSearchValueChange={handleSearchValueChange}
            handleFilterDataChange={handleFilterDataChange}
          />
        <Datatables
          {...tableData.datatable}
          {...tableData.datatableFunctions}
        />
      </PageBox>
    </div>
  );
};

export default ViewTargetsView;
