import React, { useMemo } from "react";
import useStateCreate from "./StateCreate.hook";
import WaitingComponent from "../../../../components/Waiting.component";
import styles from "./Style.module.css";
import { ButtonBase, makeStyles, MenuItem } from "@material-ui/core";
import MuiStyle from "../../../../libs/MuiStyle";
import csx from "classnames";
import CustomTextField from "../../../../components/FormFields/TextField/TextField.component";
import CustomSelectField from "../../../../components/FormFields/SelectField/SelectField.component";
import CustomToggle from "../../../../components/FormFields/CustomToggle";

const useStyle = makeStyles(MuiStyle);

const StateCreate = ({
  id,
  isOpen,
  handleClose,
  editId,
  handleSideToggle,
  data,
  detailId,
}) => {
  const {
    isLoading,
    isSubmitting,
    errorData,
    form,
    changeTextData,
    onBlurHandler,
    handleSubmit,
    empData,
    listData,
  } = useStateCreate({
    id,
    isOpen,
    handleClose,
    editId,
    handleSideToggle,
    data,
    detailId,
  });
  const classes = useStyle();

  // if (isLoading) {
  //   return <WaitingComponent />;
  // }

  return (
    <div className={styles.panelCont}>
      {!editId && (
        <div className={"formFlex"}>
          <div className={"formGroup"}>
            <div className={styles.key}>
              <span className={styles.value}>Zone Name:</span>
              {data?.name}
            </div>
          </div>
          <div className={"formGroup"}>
            <div className={styles.key}>
              <span className={styles.value}>Code:</span>
              {data?.code}
            </div>
          </div>
        </div>
      )}
      {editId && (
        <div className={"formFlex"}>
          <div className={"formGroup"}>
            <CustomSelectField
              label={"Zone Name"}
              value={form?.zone_id}
              handleChange={(value) => {
                changeTextData(value, "zone_id");
              }}
            >
              {listData?.ZONES?.map((dT) => {
                return (
                  <MenuItem value={dT?.id} key={dT?.id}>
                    {dT?.name}
                  </MenuItem>
                );
              })}
            </CustomSelectField>
          </div>
        </div>
      )}
      <div className={"formFlex"}>
        <div className={"formGroup"}>
          <CustomTextField
            isError={errorData?.name}
            errorText={errorData?.name}
            label={"State Name"}
            // type="number"
            value={form?.name}
            onTextChange={(text) => {
              changeTextData(text, "name");
            }}
            onBlur={() => {
              onBlurHandler("name");
            }}
          />
        </div>
      </div>
      <div className={"formFlex"}>
        <div className={"formGroup"}>
          <CustomTextField
            isError={errorData?.code}
            errorText={errorData?.code}
            label={"State Code"}
            type="number"
            value={form?.code}
            onTextChange={(text) => {
              changeTextData(text, "code");
            }}
            onBlur={() => {
              onBlurHandler("code");
            }}
          />
        </div>
      </div>
      {editId && (
        <>
         
          <div className={styles.btm}>
          <h4 >
            <div className={"heading"}>Status:</div>
          </h4>
            <div>
              <CustomToggle
                value={form?.is_active}
                handleChange={() => {
                  changeTextData(!form?.is_active, "is_active");
                }}
                leftLabel={"Inactive"}
                rightLabel={"Active"}
              />
            </div>
          </div>
        </>
      )}
      <div className={styles.btnView}>
        <ButtonBase
          disabled={isSubmitting}
          onClick={handleSubmit}
          className={"createBtn"}
        >
          {editId ? "UPDATE" : "CREATE"}
        </ButtonBase>
      </div>
    </div>
  );
};

export default StateCreate;
